import { useSceneEntities } from "@/stores/SceneEntities";
import { useAssetFaucett17438 } from "@/scripts/rooms/actions/general/AssetFaucett17438";
import { useUIRadioGroup } from "@/scripts/rooms/actions/general/UIRadioGroup";
export function useAssetFaucets(payload, viewport) {
  //faucet UI
  const sceneEntities = useSceneEntities();

  let t17438 = sceneEntities.getUIData("t17438");
  let t17453 = sceneEntities.getUIData("t17453");
  let t17464 = sceneEntities.getUIData("t17464");
  let t17464i = sceneEntities.getUIData("t17464i");
  let t17494i = sceneEntities.getUIData("t17494i");
  let t27959 = sceneEntities.getUIData("t27959");

  if (
    !t17438.enabled &&
    !t17453.enabled &&
    !t17464.enabled &&
    !t17464i.enabled &&
    !t17494i.enabled &&
    !t27959.enabled
  ) {
    t17438.enabled = true;
    useUIRadioGroup(t17438, viewport);
    useAssetFaucett17438(t17438, viewport);
  }

  

  

  
}
