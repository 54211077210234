import { useSceneEntities } from "@/stores/SceneEntities";
import { useButtonsContainer1 } from "@/scripts/ui/buttons/ButtonsContainer1";
import { useButtonsContainer2 } from "@/scripts/ui/buttons/ButtonsContainer2";
import { useButton5 } from "@/scripts/ui/buttons/Button5";
import { useComponentStylingType9 } from "@/scripts/ui/ComponentStylingType9";
import { useComponentStylingType11 } from "@/scripts/ui/ComponentStylingType11";
import { useComponentStylingType10 } from "@/scripts/ui/ComponentStylingType10";


export function usePresetsBuild() {
 

  const sceneEntities = useSceneEntities();

  //get side nav preset UI
  let presets_persistant = sceneEntities.getUIData("presets_persistant");
  presets_persistant.children = [];

  // need to also do top nav presets
  let presets_persistant_topnav = sceneEntities.getUIData("presets_persistant_topnav");

  presets_persistant_topnav.children = [];

  let addPreset = useButton5();
  let addPreset2 = useButton5();
  addPreset2.attributes.styling = useComponentStylingType11();
  addPreset2.attributes.componentData =  addPreset.attributes.componentData;

  presets_persistant.children.push(addPreset);
  presets_persistant_topnav.children.push(addPreset2);

  addPreset.attributes.componentData.display.label = "Add Preset";
  addPreset.attributes.componentData.skipSerialization = true;
  addPreset.attributes.componentData.key = "preset_add_1";
  addPreset.attributes.componentData.sharedKey = "preset_add_shared";
  sceneEntities.addItemToUIList(addPreset.attributes.componentData);
  addPreset.attributes.componentData.interaction.actions["scene_update"] = true;

   sceneEntities.loadPresetsPersistant();

   let validDefaults = sceneEntities.presetData.default.filter((preset) => preset.isDeleted !== true);
   let joinedArrays = [].concat(sceneEntities.presetData.persistant,validDefaults);
   joinedArrays.sort((a, b) => {
    if (a.label < b.label) {
      return -1;
    }
    if (a.label > b.label) {
      return 1;
    }
    return 0;
  });

  joinedArrays.forEach((element) => {
    let objectOption = useButtonsContainer1();

    let objectOption2 = useButtonsContainer1();
    objectOption2.attributes.componentData = objectOption.attributes.componentData;
    objectOption2.attributes.styling = useComponentStylingType9();
   
    presets_persistant.children.push(objectOption);
    presets_persistant_topnav.children.push(objectOption2);
    objectOption.attributes.componentData.display.label = element.label;
    objectOption.attributes.componentData.key = element.key;
    objectOption.attributes.componentData.isDefault = element.isDefault;
    objectOption.attributes.componentData.sharedKey = "preset_selection";
    sceneEntities.addItemToUIList(objectOption.attributes.componentData);
    objectOption.attributes.componentData.interaction.actions["scene_update"] = true;
  });

  //session presets 

     //get side nav preset UI
  let presets_session = sceneEntities.getUIData("presets_session");
  presets_session.children = [];

  // need to also do top nav presets
  let presets_session_topnav = sceneEntities.getUIData("presets_session_topnav");
  presets_session_topnav.children = [];

    let saveSession = useButton5();
  let saveSession2 = useButton5();
  saveSession2.attributes.styling = useComponentStylingType11();
  saveSession2.attributes.componentData =  saveSession.attributes.componentData;

  presets_session.children.push(saveSession);
  presets_session_topnav.children.push(saveSession2);

  saveSession.attributes.componentData.display.label = "Save Session";
  saveSession.attributes.componentData.skipSerialization = true;
  saveSession.attributes.componentData.key = "save_session_1";
  saveSession.attributes.componentData.sharedKey = "session_save_shared";
  sceneEntities.addItemToUIList(saveSession.attributes.componentData);
  saveSession.attributes.componentData.interaction.actions["scene_update"] = true;


  sceneEntities.loadPresetsSession();

  sceneEntities.presetData.session.forEach((element) => {
    let objectOption = useButtonsContainer2();
    let objectOption2 = useButtonsContainer2();

    objectOption2.attributes.componentData = objectOption.attributes.componentData;
    objectOption2.attributes.styling = useComponentStylingType10();

    presets_session.children.push(objectOption);
    presets_session_topnav.children.push(objectOption2);
    objectOption.attributes.componentData.display.label = element.label;
    objectOption.attributes.componentData.key = element.key;
    
    objectOption.attributes.componentData.sharedKey = "preset_selection";
    sceneEntities.addItemToUIList(objectOption.attributes.componentData);
    objectOption.attributes.componentData.interaction.actions["scene_update"] = true;
  });
}
