import { useAccordion2 } from "@/scripts/ui/accordions/Accordion2";
import { useDivider1 } from "@/scripts/ui/dividers/Divider1";
import { useButton2 } from "@/scripts/ui/buttons/Button2";
import { useButton4 } from "@/scripts/ui/buttons/Button4";
import { useFlexContainer1 } from "@/scripts/ui/flex_containers/FlexContainer1";
import { useButtonRadioGroup } from "@/scripts/ui/ButtonRadioGroup";
import { useSceneEntities } from "@/stores/SceneEntities";
export function uset27959() {

   
    let finishOptionRadioGroup = useButtonRadioGroup();
    const sceneEntities = useSceneEntities();
  let target = useAccordion2();
  target.attributes.componentData.display.label = "Tri Setting - t27959";
  target.attributes.componentData.key = "t27959"; 
  sceneEntities.addItemToUIList(target.attributes.componentData);
  target.attributes.componentData.interaction.deselectSelf = false;
  target.attributes.componentData.interaction.actions["scene_update"] = true;
  



  return target;
}
