export function useComponentData() {
  const data = {
    key: null,
    skipSerialization:false,
    sharedKey:null,
    styling: null,
    interaction: null,
    display: null,
    content: null,
    vifValue: true,
    disabled: false,
    enabled: false,
    children: null,
    parent: null,
    addChild(child) {
     
      child.attributes.componentData.parent = this;
      this.children.push(child);
    },
    parentEnabledOverridesChildEnabled: false,
    isEnabled() {
   
      if (!this.parent || !this.parentEnabledOverridesChildEnabled) {
        return this.enabled;
      } else {
       
      
        return this.enabled && this.parent.isEnabled();
      }
    },
  };

  return data;
}
