import { useSceneEntities } from "@/stores/SceneEntities";

export function useAssetFaucett17494iSideLeft(payload, viewport) {
    const sceneEntities = useSceneEntities();

   
    let t17464i_sidebarleft = sceneEntities.getUIData("t17464i_sidebarleft");
    let t17464i = sceneEntities.getUIData("t17464i");

    let t17494i_head_slide_left = sceneEntities.configuratorView.viewportComponent.getViewportAsset("t17494i_head_slide_left");
    t17494i_head_slide_left.disabled = payload.disabled;
    t17494i_head_slide_left.enabled = payload.enabled;
    viewport.setEnabled(t17494i_head_slide_left);

    let head_slide_hose_left = sceneEntities.configuratorView.viewportComponent.getViewportAsset("head_slide_hose_left");
    head_slide_hose_left.disabled = payload.disabled;
    head_slide_hose_left.enabled = payload.enabled || (t17464i_sidebarleft.enabled && t17464i.enabled);
    viewport.setEnabled(head_slide_hose_left);

  

}