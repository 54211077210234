
export function useComponentStylingType11() {
   
    const data = {
        classStringContainerDefault:"container-type-7 py-1",
        classStringContainerSelected:"container-type-7 py-1",
        classStringTextDefault:"text-type-7 py-3 text-color-3",
        classStringTextSelected:"text-type-7 py-3 text-color-3",       
      
     
          };

 
  return data;
}