import { useButtonInteraction } from "@/scripts/ui/ButtonInteraction";
import { useComponentDisplayData } from "@/scripts/ui/ComponentDisplayData";
import { useComponentData } from "@/scripts/ui/ComponentData";
import { useComponentStylingType7 } from "@/scripts/ui/ComponentStylingType7";
export function useButton5() {

    let data = {};
    data.component = "CofigurableButtonComponent";
    let attr = data.attributes = {};
    attr.componentData = useComponentData();
    attr.componentData.content = "CofigurableButtonContent3Component";
    attr.componentData.styling = useComponentStylingType7();
    attr.componentData.interaction = useButtonInteraction();
    attr.componentData.display = useComponentDisplayData();
  

  return data;
}
