<template>
  <component
    :key="invalidateComponentKey"
    ref="viewportComponent"
    :is="viewportFactoryKey"
    @vue:mounted="onViewportMounted"
  ></component>
</template>

<script setup>
import EventNames from "@/scripts/events/EventNames";
import PublishSubscribe from "@/scripts/events/PublishSubscribe";
import { useViewportFactory } from "@/scripts/viewport/ViewportFactory";
import { onUnmounted,onBeforeUnmount, onMounted, ref, watch, nextTick } from "vue";
import { useRoute } from "vue-router";
import { useSceneEntities } from "@/stores/SceneEntities";
import { useRoomFactory } from "@/scripts/rooms/RoomFactory";
import ConsoleLogAdvanced from "@/scripts/utils/ConsoleLogAdvanced";
import { useRequests } from "@/stores/Requests";
import { usePresetsBuild } from "@/scripts/rooms/actions/general/PresetsBuild";
const route = useRoute();
const roomController = useRoomFactory(route.query.roomtype || "alcove", route.query.roomoption || 1);
const viewportComponent = ref(null);
const roomtype = ref(null);
const roomoption = ref(null);



defineExpose({ 
  roomtype,
  roomoption,
  loadPreset,
  build,
  roomController,
  viewportComponent
});

const consoleLogAdvanced = new ConsoleLogAdvanced();

const publishSubscribe = new PublishSubscribe();
const sceneEntities = useSceneEntities();

var viewportFactoryKey = ref(useViewportFactory(route.query.viewport || "3d"));
var invalidateComponentKey = ref(0);


var processViewportOnMounted = false;

async function onSelect(payload) {
  roomController.processInteraction(payload);
}

watch(
  () => route.query.viewport,
  async (newViewport) => {
   

    

    processViewportOnMounted = true;
    sceneEntities.serializeScene();     
    dispose();
    viewportFactoryKey.value = useViewportFactory(newViewport || "3d");
  }
);
onBeforeUnmount(() => {
 
  sceneEntities.onSessionEndSessionSave();
})
onUnmounted(() => {
 
  dispose();
});

function dispose() {
 
  sceneEntities.dispose();
  publishSubscribe.removeEventListenerGlobal(EventNames.MENU_INTERACTION, onSelect);
}

async function loadPreset(data) {
  sceneEntities.serialization = data;
  processViewportOnMounted = true;
  dispose();
  invalidateComponentKey.value++;
}


async function parseSerializedData(data) {
  //UI
  sceneEntities.parseSerializedUI(data);

  //ASSETS
  for (let prop in data.viewportassets) {
    //try get asset
    let asset = viewportComponent.value.getViewportAsset(prop);
    let assetData = data.viewportassets[prop];
    asset.enabled = assetData.enabled;
    asset.disabled = assetData.disabled;
  }
  //MATERIALS
  viewportComponent.value.dataCollectionMaterials = data.materials || {};
}

async function onViewportMounted() {
 
  if (processViewportOnMounted) {
    processViewportOnMounted = false;
    await sceneEntities.handleDefaultBuilds(); 
    roomController.build();
    await handleDefaultData();
    await handleSerializedData();
  }
}

async function handleDefaultData() {
  publishSubscribe.addEventListenerGlobal(EventNames.MENU_INTERACTION, onSelect); 
  await viewportComponent.value.loadData(); 
  await invalidateViewport();
  usePresetsBuild();
}

async function handleSerializedData() {
  parseSerializedData(sceneEntities.serialization);
  await invalidateViewport();
  roomController.processSerializedMaterialUpdates();
}

async function invalidateViewport() {
  await viewportComponent.value.invalidate();
}

async function build() {
  

   roomtype.value = route.query.roomtype;
 roomoption.value = route.query.roomoption;



  await sceneEntities.handleDefaultBuilds(); 
  roomController.build();  
  await handleDefaultData();
  sceneEntities.serialization = await sceneEntities.getPreferredStartupData() 
  await handleSerializedData();
}
</script>

<style></style>
