import { useAccordion1 } from "@/scripts/ui/accordions/Accordion1";
import { useButton1 } from "@/scripts/ui/buttons/Button1";
import { useDivider1 } from "@/scripts/ui/dividers/Divider1";
import { useButtonRadioGroup } from "@/scripts/ui/ButtonRadioGroup";
import { useSceneEntities } from "@/stores/SceneEntities";
export function useAccessories() {
  let showerRestRadioGroup = useButtonRadioGroup();
  let innerWallRadioGroup = useButtonRadioGroup();
  let cornerShelfFinishRadioGroup = useButtonRadioGroup();
  let shaveStepRadioGroup = useButtonRadioGroup();
  let grabBarCenterRadioGroup = useButtonRadioGroup();
  
  const sceneEntities = useSceneEntities();
  let target = useAccordion1();
  target.attributes.componentData.display.label = "Accessories";
  target.attributes.componentData.key = "accessories";
  sceneEntities.addItemToUIList(target.attributes.componentData);
  let targetChildren = target.attributes.componentData.children;

  //shelves--------------------------------------------------
  let divider = useDivider1();
  targetChildren.push(divider);
  divider.attributes.componentData.display.useLeft = true;
  divider.attributes.componentData.display.useRight = true;
  divider.attributes.componentData.display.label = "Corner Shelves";
  divider.attributes.componentData.key = "divider_shelves"; 
  sceneEntities.addItemToUIList(divider.attributes.componentData);

  let objectOption = useButton1();
  targetChildren.push(objectOption);
  objectOption.attributes.componentData.display.label = "Shelf Left Top";
  objectOption.attributes.componentData.key = "accessories_shelflefttop";
  objectOption.attributes.componentData.interaction.actions["scene_update"] = true;
  sceneEntities.addItemToUIList(objectOption.attributes.componentData);

  objectOption = useButton1();
  targetChildren.push(objectOption);
  objectOption.attributes.componentData.display.label = "Shelf Left Mid";
  objectOption.attributes.componentData.key = "accessories_shelfleftmid";
  objectOption.attributes.componentData.interaction.actions["scene_update"] = true;
  sceneEntities.addItemToUIList(objectOption.attributes.componentData);

  objectOption = useButton1();
  targetChildren.push(objectOption);
  objectOption.attributes.componentData.display.label = "Shelf Left Bottom";
  objectOption.attributes.componentData.key = "accessories_shelfleftbottom";
  objectOption.attributes.componentData.interaction.actions["scene_update"] = true;
  sceneEntities.addItemToUIList(objectOption.attributes.componentData);

  objectOption = useButton1();
  targetChildren.push(objectOption);
  objectOption.attributes.componentData.display.label = "Shelf Right Top";
  objectOption.attributes.componentData.key = "accessories_shelfrighttop";
  objectOption.attributes.componentData.interaction.actions["scene_update"] = true;
  sceneEntities.addItemToUIList(objectOption.attributes.componentData);

  objectOption = useButton1();
  targetChildren.push(objectOption);
  objectOption.attributes.componentData.display.label = "Shelf Right Mid";
  objectOption.attributes.componentData.key = "accessories_shelfrightmid";
  objectOption.attributes.componentData.interaction.actions["scene_update"] = true;
  sceneEntities.addItemToUIList(objectOption.attributes.componentData);

  objectOption = useButton1();
  targetChildren.push(objectOption);
  objectOption.attributes.componentData.display.label = "Shelf Right Bottom";
  objectOption.attributes.componentData.key = "accessories_shelfrightbottom";
  objectOption.attributes.componentData.interaction.actions["scene_update"] = true;
  sceneEntities.addItemToUIList(objectOption.attributes.componentData);

  //corner shelf finish--------------------------------------------------
  divider = useDivider1();
  if (!window.isSentrelBuild) {
    targetChildren.push(divider);
  }
  divider.attributes.componentData.display.useLeft = true;
  divider.attributes.componentData.display.useRight = true;
  divider.attributes.componentData.display.label = "Shelf Finish";
  divider.attributes.componentData.key = "corner_shelf_finish";

  sceneEntities.addItemToUIList(divider.attributes.componentData);

  objectOption = useButton1();
  if (!window.isSentrelBuild) {
    targetChildren.push(objectOption);
  }
  objectOption.attributes.componentData.display.label = "Sentrel";
  objectOption.attributes.componentData.key = "corner_shelf_sentrel_material";
  objectOption.attributes.componentData.interaction.radioGroup = cornerShelfFinishRadioGroup;
  objectOption.attributes.componentData.interaction.deselectSelf = false;
  objectOption.attributes.componentData.interaction.actions["scene_update"] = true;
  sceneEntities.addItemToUIList(objectOption.attributes.componentData);

  objectOption = useButton1();
  if (!window.isSentrelBuild) {
    targetChildren.push(objectOption);
  }
  objectOption.attributes.componentData.display.label = "Metal";
  objectOption.attributes.componentData.key = "corner_shelf_metal_material";
  objectOption.attributes.componentData.interaction.radioGroup = cornerShelfFinishRadioGroup;
  objectOption.attributes.componentData.interaction.deselectSelf = false;
  objectOption.attributes.componentData.interaction.actions["scene_update"] = true;

  sceneEntities.addItemToUIList(objectOption.attributes.componentData);

  //grab bars--------------------------------------------------
  divider = useDivider1();
  targetChildren.push(divider);
  divider.attributes.componentData.display.useLeft = true;
  divider.attributes.componentData.display.useRight = true;
  divider.attributes.componentData.display.label = "Grab Bars";
  divider.attributes.componentData.key = "divider_grabbar";

  sceneEntities.addItemToUIList(divider.attributes.componentData);

  objectOption = useButton1();
  targetChildren.push(objectOption);
  objectOption.attributes.componentData.display.label = "Grab Bar Left";
  objectOption.attributes.componentData.key = "accessories_grabbarleft";
 objectOption.attributes.componentData.interaction.actions["viewport_asset_update"] = true;
  sceneEntities.addItemToUIList(objectOption.attributes.componentData);

  objectOption = useButton1();
  targetChildren.push(objectOption);
  objectOption.attributes.componentData.display.label = "Grab Bar Right";
  objectOption.attributes.componentData.key = "accessories_grabbarright";
  objectOption.attributes.componentData.interaction.actions["viewport_asset_update"] = true;
  sceneEntities.addItemToUIList(objectOption.attributes.componentData);

  objectOption = useButton1();
  targetChildren.push(objectOption);
  objectOption.attributes.componentData.display.label = "Grab Bar Center";
  objectOption.attributes.componentData.key = "accessoriesgrabbarcenter";
  objectOption.attributes.componentData.interaction.radioGroup = grabBarCenterRadioGroup;
  objectOption.attributes.componentData.interaction.actions["viewport_asset_update"] = true;
  sceneEntities.addItemToUIList(objectOption.attributes.componentData);

  objectOption = useButton1();
  targetChildren.push(objectOption);
  objectOption.attributes.componentData.display.label = "Grab Bar Center Angled";
  objectOption.attributes.componentData.key = "accessoriesgrabbarcenter45";
  objectOption.attributes.componentData.interaction.radioGroup = grabBarCenterRadioGroup;
  objectOption.attributes.componentData.interaction.actions["viewport_asset_update"] = true;
  sceneEntities.addItemToUIList(objectOption.attributes.componentData);

  //shower rests--------------------------------------------------
  divider = useDivider1();
  targetChildren.push(divider);
  divider.attributes.componentData.display.useLeft = true;
  divider.attributes.componentData.display.useRight = true;
  divider.attributes.componentData.display.label = "Shower Rests";

  divider.attributes.componentData.key = "divider_showerrests";
  sceneEntities.addItemToUIList(divider.attributes.componentData);

  objectOption = useButton1();
  targetChildren.push(objectOption);
  objectOption.attributes.componentData.display.label = "Shower Bench";
  objectOption.attributes.componentData.interaction.radioGroup = showerRestRadioGroup;
  objectOption.attributes.componentData.key = "accessories_showerbench";
  sceneEntities.addItemToUIList(objectOption.attributes.componentData);
  objectOption.attributes.componentData.interaction.actions["viewport_asset_update"] = true;


  objectOption = useButton1();
  targetChildren.push(objectOption);
  objectOption.attributes.componentData.display.label = "Shower Chair";
  objectOption.attributes.componentData.interaction.radioGroup = showerRestRadioGroup;
  objectOption.attributes.componentData.key = "accessories_showerchair";
  sceneEntities.addItemToUIList(objectOption.attributes.componentData);
  objectOption.attributes.componentData.interaction.actions["viewport_asset_update"] = true;

  objectOption = useButton1();
  targetChildren.push(objectOption);
  objectOption.attributes.componentData.display.label = "Corner Seat";
  objectOption.attributes.componentData.interaction.radioGroup = showerRestRadioGroup;
  objectOption.attributes.componentData.key = "accessories_cornerseat";
  sceneEntities.addItemToUIList(objectOption.attributes.componentData);
  objectOption.attributes.componentData.interaction.actions["viewport_asset_update"] = true;

  objectOption = useButton1();
  targetChildren.push(objectOption);
  objectOption.attributes.componentData.display.label = "Shave Step";
  objectOption.attributes.componentData.key = "accessories_shavestep";
  sceneEntities.addItemToUIList(objectOption.attributes.componentData);
  objectOption.attributes.componentData.interaction.radioGroup = shaveStepRadioGroup;
  objectOption.attributes.componentData.interaction.actions["viewport_asset_update"] = true;

  if (!window.isSentrelBuild) {
    objectOption = useButton1();
    targetChildren.push(objectOption);
    objectOption.attributes.componentData.display.label = "Metal Shave Bar";
    objectOption.attributes.componentData.key = "accessories_shavestep_metal";
    sceneEntities.addItemToUIList(objectOption.attributes.componentData);
    objectOption.attributes.componentData.interaction.radioGroup = shaveStepRadioGroup;
    objectOption.attributes.componentData.interaction.actions["viewport_asset_update"] = true;
  }

  //ceiling--------------------------------------------------
  divider = useDivider1();
  targetChildren.push(divider);
  divider.attributes.componentData.display.useLeft = true;
  divider.attributes.componentData.display.useRight = true;
  divider.attributes.componentData.display.label = "Ceiling";
  divider.attributes.componentData.key = "divider_ceiling";
  sceneEntities.addItemToUIList(divider.attributes.componentData);
  objectOption.attributes.componentData.interaction.actions["viewport_asset_update"] = true;

  objectOption = useButton1();
  targetChildren.push(objectOption);
  objectOption.attributes.componentData.display.label = "Ceiling Trim";
  objectOption.attributes.componentData.key = "accessories_ceilingtrim";
  sceneEntities.addItemToUIList(objectOption.attributes.componentData);
  objectOption.attributes.componentData.interaction.actions["scene_update"] = true;

  objectOption = useButton1();
  targetChildren.push(objectOption);
  objectOption.attributes.componentData.display.label = "Ceiling Panel";
  objectOption.attributes.componentData.key = "accessories_ceilingpanel";
  sceneEntities.addItemToUIList(objectOption.attributes.componentData);
  objectOption.attributes.componentData.interaction.actions["viewport_asset_update"] = true;

  objectOption = useButton1();
  targetChildren.push(objectOption);
  objectOption.attributes.componentData.display.label = "Soffit Cover";
  objectOption.attributes.componentData.key = "soffit_cover";
  sceneEntities.addItemToUIList(objectOption.attributes.componentData);
  objectOption.attributes.componentData.interaction.actions["viewport_asset_update"] = true;

  //inner wall--------------------------------------------------
  divider = useDivider1();
  targetChildren.push(divider);
  divider.attributes.componentData.display.useLeft = true;
  divider.attributes.componentData.display.useRight = true;
  divider.attributes.componentData.display.label = "Inner Wall";
  divider.attributes.componentData.key = "divider_innerwall";

  objectOption = useButton1();
  targetChildren.push(objectOption);
  objectOption.attributes.componentData.display.label = "Regular";
  objectOption.attributes.componentData.interaction.radioGroup = innerWallRadioGroup;
  objectOption.attributes.componentData.key = "accessories_recess_regular";
  sceneEntities.addItemToUIList(objectOption.attributes.componentData);
  objectOption.attributes.componentData.interaction.deselectSelf = false;
  objectOption.attributes.componentData.interaction.actions["scene_update"] = true;

  objectOption = useButton1();
  targetChildren.push(objectOption);
  objectOption.attributes.componentData.display.label = "Window";
  objectOption.attributes.componentData.interaction.radioGroup = innerWallRadioGroup;
  objectOption.attributes.componentData.key = "accessories_recess_window";
  sceneEntities.addItemToUIList(objectOption.attributes.componentData);
  objectOption.attributes.componentData.interaction.deselectSelf = false;
  objectOption.attributes.componentData.interaction.actions["scene_update"] = true;

  objectOption = useButton1();
  targetChildren.push(objectOption);
  objectOption.attributes.componentData.display.label = "Soap 1";
  objectOption.attributes.componentData.interaction.radioGroup = innerWallRadioGroup;
  objectOption.attributes.componentData.key = "accessories_recess_soap1";
  objectOption.attributes.componentData.conditional_display_gates = {};
  sceneEntities.addItemToUIList(objectOption.attributes.componentData);
  objectOption.attributes.componentData.interaction.deselectSelf = false;
  objectOption.attributes.componentData.interaction.actions["scene_update"] = true;

  objectOption = useButton1();
  targetChildren.push(objectOption);
  objectOption.attributes.componentData.display.label = "Soap 2";
  objectOption.attributes.componentData.interaction.radioGroup = innerWallRadioGroup;
  objectOption.attributes.componentData.key = "accessories_recess_soap2";
  sceneEntities.addItemToUIList(objectOption.attributes.componentData);
  objectOption.attributes.componentData.interaction.deselectSelf = false;
  objectOption.attributes.componentData.interaction.actions["scene_update"] = true;

  objectOption = useButton1();
  targetChildren.push(objectOption);
  objectOption.attributes.componentData.display.label = "Soap 1 + Window";
  objectOption.attributes.componentData.interaction.radioGroup = innerWallRadioGroup;
  objectOption.attributes.componentData.key = "accessories_recess_soap1window";
  sceneEntities.addItemToUIList(objectOption.attributes.componentData);
  objectOption.attributes.componentData.interaction.deselectSelf = false;
  objectOption.attributes.componentData.interaction.actions["scene_update"] = true;

  objectOption = useButton1();
  targetChildren.push(objectOption);
  objectOption.attributes.componentData.display.label = "Soap 2 + Window";
  objectOption.attributes.componentData.interaction.radioGroup = innerWallRadioGroup;
  objectOption.attributes.componentData.key = "accessories_recess_soap2window";
  sceneEntities.addItemToUIList(objectOption.attributes.componentData);
  objectOption.attributes.componentData.interaction.deselectSelf = false;
  objectOption.attributes.componentData.interaction.actions["scene_update"] = true;



  if (!window.isSentrelBuild) {/*
  objectOption = useButton1();
  targetChildren.push(objectOption);
  objectOption.attributes.componentData.display.label = "Steel Shelf 1";
  objectOption.attributes.componentData.interaction.radioGroup = innerWallRadioGroup;
  objectOption.attributes.componentData.key = "accessories_recess_steelshelf1";
  sceneEntities.addItemToUIList(objectOption.attributes.componentData);
  objectOption.attributes.componentData.interaction.deselectSelf = false;
  objectOption.attributes.componentData.interaction.actions["scene_update"] = true;

  objectOption = useButton1();
  targetChildren.push(objectOption);
  objectOption.attributes.componentData.display.label = "Steel Shelf 2";
  objectOption.attributes.componentData.interaction.radioGroup = innerWallRadioGroup;
  objectOption.attributes.componentData.key = "accessories_recess_steelshelf2";
  sceneEntities.addItemToUIList(objectOption.attributes.componentData);
  objectOption.attributes.componentData.interaction.deselectSelf = false;
  objectOption.attributes.componentData.interaction.actions["scene_update"] = true;
*/
  
    objectOption = useButton1();
    targetChildren.push(objectOption);
    objectOption.attributes.componentData.display.label = "Custom Soap 1 Shelf";
    objectOption.attributes.componentData.interaction.radioGroup = innerWallRadioGroup;
    objectOption.attributes.componentData.key = "accessories_recess_custom_soap_1";
    sceneEntities.addItemToUIList(objectOption.attributes.componentData);
    objectOption.attributes.componentData.interaction.deselectSelf = false;
    objectOption.attributes.componentData.interaction.actions["scene_update"] = true;

    objectOption = useButton1();
    targetChildren.push(objectOption);
    objectOption.attributes.componentData.display.label = "Custom Soap 2 Shelves";
    objectOption.attributes.componentData.interaction.radioGroup = innerWallRadioGroup;
    objectOption.attributes.componentData.key = "accessories_recess_custom_soap_2";
    sceneEntities.addItemToUIList(objectOption.attributes.componentData);
    objectOption.attributes.componentData.interaction.deselectSelf = false;
    objectOption.attributes.componentData.interaction.actions["scene_update"] = true;

  }

  return target;
}
