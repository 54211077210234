import { useDividerDisplayData } from "@/scripts/ui/DividerDisplayData";
import { useComponentData } from "@/scripts/ui/ComponentData";
import { useDividerStyling1 } from "@/scripts/ui/DividerStyling1";
import { useButtonInteraction } from "@/scripts/ui/ButtonInteraction";
export function useDivider1() {
  let data = {};
  data.component = "DividerComponent";
  let attr = (data.attributes = {});
  attr.componentData = useComponentData();
  //set this to true because these are not interactive but are serialized
  //attr.componentData.enabled = true;
  attr.componentData.styling = useDividerStyling1();
  attr.componentData.display = useDividerDisplayData();  
  attr.componentData.display.useLeft = true;
  attr.componentData.display.useRight = true;

  return data;
}
