import { useAccordion2 } from "@/scripts/ui/accordions/Accordion2";
import { useDivider1 } from "@/scripts/ui/dividers/Divider1";
import { useButton2 } from "@/scripts/ui/buttons/Button2";
import { useButton4 } from "@/scripts/ui/buttons/Button4";
import { useFlexContainer1 } from "@/scripts/ui/flex_containers/FlexContainer1";
import { useButtonRadioGroup } from "@/scripts/ui/ButtonRadioGroup";
import { useSceneEntities } from "@/stores/SceneEntities";
export function uset17464i() {
  let objectOptionRadioGroup = useButtonRadioGroup();
  let finishOptionRadioGroup = useButtonRadioGroup();
  const sceneEntities = useSceneEntities();
  let target = useAccordion2();
  target.attributes.componentData.display.label = "Ashlyn Square - t17464-i";
  target.attributes.componentData.key = "t17464i"; 
  sceneEntities.addItemToUIList(target.attributes.componentData); 
  target.attributes.componentData.interaction.deselectSelf = false;
  target.attributes.componentData.interaction.actions["scene_update"] = true;

  //Object Options--------------------------------------------------
  let divider = useDivider1();
  target.attributes.componentData.addChild(divider);
  divider.attributes.componentData.display.useLeft = true;
  divider.attributes.componentData.display.useRight = true;
  divider.attributes.componentData.display.label = "Object Options";

  //Cradle Only--------------------------------------------------
  let objectOption = useButton2();
  target.attributes.componentData.addChild(objectOption);
  objectOption.attributes.componentData.display.label = "Cradle Only";
  objectOption.attributes.componentData.interaction.radioGroup =
    objectOptionRadioGroup;
  objectOption.attributes.componentData.key = "t17464i_cradleonly";
  sceneEntities.addItemToUIList(objectOption.attributes.componentData);
  objectOption.attributes.componentData.interaction.deselectSelf = false;
  objectOption.attributes.componentData.interaction.actions["scene_update"] = true;
  objectOption.attributes.componentData.parentEnabledOverridesChildEnabled = true;

  //Cradle + Slide Bar Left--------------------------------------------------
  objectOption = useButton2();
  target.attributes.componentData.addChild(objectOption);
  objectOption.attributes.componentData.display.label =
    "Cradle + Slide Bar Left";
  objectOption.attributes.componentData.interaction.radioGroup =
    objectOptionRadioGroup;
  objectOption.attributes.componentData.key = "t17464i_sidebarleft";
  sceneEntities.addItemToUIList(objectOption.attributes.componentData);
  objectOption.attributes.componentData.interaction.deselectSelf = false;
  objectOption.attributes.componentData.interaction.actions["scene_update"] = true;
  objectOption.attributes.componentData.parentEnabledOverridesChildEnabled = true;
 

  //Cradle + Slide Bar Right--------------------------------------------------
  objectOption = useButton2();
  target.attributes.componentData.addChild(objectOption);
  objectOption.attributes.componentData.display.label =
    "Cradle + Slide Bar Right";
  objectOption.attributes.componentData.interaction.radioGroup =
    objectOptionRadioGroup;
  objectOption.attributes.componentData.key = "t17464i_sidebarright";
  sceneEntities.addItemToUIList(objectOption.attributes.componentData);
  objectOption.attributes.componentData.interaction.deselectSelf = false;
  objectOption.attributes.componentData.interaction.actions["scene_update"] = true;
  objectOption.attributes.componentData.parentEnabledOverridesChildEnabled = true;
  

  

  

  return target;
}
