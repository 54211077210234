import { useSceneEntities } from "@/stores/SceneEntities";
import { useUIRadioGroup } from "@/scripts/rooms/actions/general/UIRadioGroup";
import { useMaterialAccents } from "@/scripts/rooms/actions/general/MaterialAccents";
import { useAssetAccentValidate1 } from "@/scripts/rooms/actions/alcove/AssetAccentValidate1";

export function useAssetAccents(payload, viewport) {
  const sceneEntities = useSceneEntities();

  //handle if no accent is selected yet 
  if (payload.enabled) {
    let hasAccentSelection = false;
    payload.additionalTriggersSoft.every((element) => {
     
      let ui = sceneEntities.getUIData(element);
      if (ui.enabled) {
        hasAccentSelection = true;
        return false;
      } else {
        return true;
      }
    });
    if (!hasAccentSelection) {
      let ui = sceneEntities.getUIData(payload.additionalTriggersSoft[0]);
      ui.enabled = true;
      useUIRadioGroup(ui);
      useMaterialAccents(ui,viewport);
    }
  }

  useAssetAccentValidate1(payload,viewport)
}
