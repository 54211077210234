<template>
  <div class="w-100 content-bg-1">
    <div class="w-100 nav-bar-custom navbar-bg-1">
      <ItemContainerLeftRightSlotsComponent>
        <template #right>
          <router-link to="/applicationlanding/roomselect" custom v-slot="{ navigate }">
            <button class="home-button" @click="navigate" @keypress.enter="navigate">
              <span class="mdi icon icon-color-2 mdi-home"></span>
            </button>
          </router-link>
        </template>
      </ItemContainerLeftRightSlotsComponent>
    </div>
    <div class="w-100">
      <div
        class="w-100 ma-4 mx-auto content-bg-6 elevation-4"
        style="max-width: 900px"
        :class="{ 'pa-6': !isSmallerScreens, 'pa-2': isSmallerScreens }"
      >
        <div class="mt-2 p-1 fs-2 fw-bolder text-color-1">Caching Utility</div>
        <div v-for="(item, index) in cacheTracking.value" :key="index">
          <div class="mt-2 p-1 fs-2 fw-bolder text-color-1">
            {{ item.header }}
          </div>

          <div class="my-2 p-1 fs-6 fw-normal text-color-1">
            {{ item.meshText }}
          </div>

          <div class="my-2 p-1 fs-6 fw-normal text-color-1">
            {{ item.textureText }}
          </div>
        </div>

        <b-button v-show="!cacheAllComplete && !isRunningCache" @click="processStart" variant="primary">Start</b-button>
        <b-button v-show="!cacheAllComplete && isRunningCache" @click="processCancel" variant="primary"
          >Cancel</b-button
        >
        <router-link to="/applicationlanding/roomselect" custom v-slot="{ navigate }">
          <b-button v-show="cacheAllComplete" @click="navigate" @keypress.enter="navigate" variant="primary"
            >Home</b-button
          >
        </router-link>
      </div>
    </div>
  </div>
</template>
<script setup>
import { computed, ref, onUnmounted, reactive } from "vue";
import ItemContainerLeftRightSlotsComponent from "@/components/ItemContainerLeftRightSlotsComponent";
import { useSceneEntities } from "@/stores/SceneEntities";
import { useRoomFactory } from "@/scripts/rooms/RoomFactory";
import { useIsSmallerScreens } from "@/scripts/composables/IsSmallerScreens";

import { Engine } from "@babylonjs/core/Engines/engine";
import { Scene } from "@babylonjs/core/scene";
import { SceneLoader } from "@babylonjs/core/Loading/sceneLoader";
import { Texture } from "@babylonjs/core/Materials/Textures/texture";
import { ArcRotateCamera } from "@babylonjs/core/Cameras/arcRotateCamera";
import { Vector3 } from "@babylonjs/core/Maths/math";
import "@babylonjs/core/Helpers/sceneHelpers";
import { useRequests } from "@/stores/Requests";

const buildNumber = ref(process.env.VUE_APP_BUILD_NUMBER || 0);

const isSmallerScreens = useIsSmallerScreens();

var scene = null;
var engine = null;
const cacheTracking = reactive({ value: {} });
let isRunningCache = ref(false);
let currentCacheLabel = ref("");
let cacheAllComplete = ref(false);
var cacheMeshComplete = false;
var cacheTextureComplete = false;
var loadAllDataComplete = false;
let sceneCreated = false;
let meshIndex = ref(0);
let textureIndex = ref(0);
let entityListAssets = new Map();
let entityListTextures = new Map();
var meshTotal = 0;

var textureTotal = 0;

let currentTrackingObject;

const sceneEntities = useSceneEntities();
var roomFactoryKeys = ["alcove", "corner"];
var roomFactoryKeysIndex = 0;
var assetIterator;
var textureIterator;

async function processStart() {
  //start loading loops
  isRunningCache.value = true;
  roomFactoryKeysIndex = 0;
  cacheAllComplete.value = false;
  cacheMeshComplete = false;
  cacheTextureComplete = false;
  loadAllDataComplete = false;
  entityListAssets.clear();
  entityListTextures.clear();
  meshIndex.value = 0;
  meshTotal = 0;
  textureIndex.value = 0;
  textureTotal = 0;
  cacheTracking.value = {};
  if (!sceneCreated) {
    await createScene();
  }
  processLoadData();
}

async function createScene() {
  engine = new Engine(document.createElement("canvas"), true);
  scene = new Scene(engine);
  scene.createDefaultCamera();

  engine.runRenderLoop(() => {
    scene.render();
  });
  await awaitingSceneReady();
}

function until(predFn) {
  const poll = (done) => {
    predFn() ? done() : setTimeout(() => poll(done), 100);
  };
  return new Promise(poll);
}
async function awaitingSceneReady() {
  scene.onReadyObservable.addOnce(() => {
    sceneCreated = true;
  });
  return await until(() => {
    return sceneCreated === true;
  });
}

function processCancel() {
  isRunningCache.value = false;
  cacheAllComplete.value = false;
}

function processComplete() {
  if (cacheMeshComplete && cacheTextureComplete && !loadAllDataComplete) {
    processLoadData();
    return;
  }

  cacheAllComplete.value = cacheMeshComplete && cacheTextureComplete && loadAllDataComplete;
  isRunningCache.value = !cacheAllComplete.value;
}

async function loadAssetsData(url) {
  const requests = useRequests();
  let result;
  try {
    let ob = { url };
    result = await requests.get({ url });
  } catch (error) {
    console.log(error);
    return;
  }
  if (result) {
    parseAssetsData(result.data);
  }
}

function parseAssetsData(data) {
  for (let prop in data) {
    let assetDataItem = data[prop];
    entityListAssets.set(prop, assetDataItem);
  }
}

async function loadTexturesData(url) {
  const requests = useRequests();
  let result;
  try {
    result = await requests.get({ url });
  } catch (error) {
    console.log(error);
    return;
  }
  if (result) {
    parseTexturesData(result.data);
  }
}

function parseTexturesData(data) {
  for (let prop in data) {
    let assetDataItem = data[prop];
    entityListTextures.set(prop, assetDataItem);
  }
}

async function processLoadData() {
  if (roomFactoryKeysIndex >= roomFactoryKeys.length) {
    loadAllDataComplete = true;
    processComplete();
    return;
  }

  let roomFactoryKey = roomFactoryKeys[roomFactoryKeysIndex];
  currentCacheLabel.value = roomFactoryKey;
  roomFactoryKeysIndex++;

  //!!!! take note , rooms have sub option numbers , for now there is only one option per room so just hard coding
  //a single number here .. in future these need to be looped

  let roomController = useRoomFactory(roomFactoryKey, 1);

  entityListAssets.clear();
  entityListTextures.clear();

  await loadAssetsData(roomController.dataPaths.assetsURL);
  await loadTexturesData(roomController.dataPaths.texturesURL);

  //reset mesh and texture complete flags as a new data set is going to be loaded now
  cacheMeshComplete = false;
  cacheTextureComplete = false;
  meshTotal = entityListAssets.size;
  textureTotal = entityListTextures.size;
  meshIndex.value = 0;
  textureIndex.value = 0;

  let ob = cacheTracking.value;
  currentTrackingObject = ob[roomFactoryKey] = {};
  currentTrackingObject.header = "Caching " + roomFactoryKey;

  currentTrackingObject.meshText = "Mesh " + meshIndex.value + " of " + meshTotal;

  currentTrackingObject.textureText = "Texture " + textureIndex.value + " of " + textureTotal;

  //create new iterators from maps for assets and textuers
  assetIterator = entityListAssets.keys();
  textureIterator = entityListTextures.keys();
  loopMeshDataCollection();
  loopTextureDataCollection();
}

function loopMeshDataCollection() {
  let iterable = assetIterator.next();
  if (!iterable.done) {
    let item = entityListAssets.get(iterable.value);

    loadMesh(item.url);
  } else {
    cacheMeshComplete = true;
    processComplete();
  }
}
function loopTextureDataCollection() {
  let iterable = textureIterator.next();
  if (!iterable.done) {
    let item = entityListTextures.get(iterable.value);

    loadTexture(item);
  } else {
    cacheTextureComplete = true;
    processComplete();
  }
}

function loadTexture(url) {
  textureIndex.value++;

  currentTrackingObject.textureText = "Texture " + textureIndex.value + " of " + textureTotal;
  cacheTracking.value = { ...cacheTracking.value };
  let texture = new Texture(window.location_url + url + "?cache_uid=" + buildNumber.value);
  texture.onLoadObservable.addOnce((eventData) => {
    textureLoadSuccess();
  });
}

function textureLoadSuccess() {
  if (isRunningCache.value) {
    loopTextureDataCollection();
  }
}

function loadMesh(url) {
  meshIndex.value++;

  currentTrackingObject.meshText = "Mesh " + meshIndex.value + " of " + meshTotal;
  cacheTracking.value = { ...cacheTracking.value };
  

  if (!url) {
    meshLoadSuccess();
    return;
  }
 
  SceneLoader.ImportMesh(
    "",
    "",
    window.location_url + url+ "?cache_uid=" + buildNumber.value,
    scene,
    //niffty feature of bind I did not know .. can have it call the function in more than one context
    meshLoadSuccess,
    meshLoadProgress,
    meshLoadError
  );
}
function meshLoadSuccess() {
  if (isRunningCache.value) {
    loopMeshDataCollection();
  }
}
function meshLoadProgress() {}
function meshLoadError() {}

onUnmounted(() => {
  dispose();
});

function dispose() {
  processCancel();

  scene?.dispose();

  sceneEntities.dispose();
}
</script>

<style scoped>
.home-button {
  background-color: transparent;
  border: 0;
}

.nav-bar-custom {
  align-content: center;
  justify-content: flex-start;
  height: 48px;
}
</style>
