import { createApp } from 'vue'
import App from '@/App.vue'
import store from "@/stores/index.js";
import router from '@/router'
import BootstrapVue from 'bootstrap-vue-3';
import {BFormInput,BFormTextarea,BFormCheckbox,BFormSelect} from 'bootstrap-vue-3';
import AccordionComponent from "@/components/AccordionComponent";
import ContainerComponent from "@/components/ContainerComponent";
import PaletteComponent from "@/components/PaletteComponent";
import CofigurableButtonComponent from "@/components/CofigurableButtonComponent";
import CofigurableButtonContent1Component from "@/components/CofigurableButtonContent1Component";
import CofigurableButtonContent2Component from "@/components/CofigurableButtonContent2Component";
import CofigurableButtonContent3Component from "@/components/CofigurableButtonContent3Component";
import ConfigurableButtonsContainerComponent from "@/components/ConfigurableButtonsContainerComponent";
import CofigurableButtonContent4Component from "@/components/CofigurableButtonContent4Component";
import CofigurableButtonContent5Component from "@/components/CofigurableButtonContent5Component";


import FlexContainerComponent from "@/components/FlexContainerComponent";
import DividerComponent from "@/components/DividerComponent";
import Header1Component from "@/components/Header1Component";
import Viewport3D from "@/scripts/viewport/3d/Viewport3D";
import Viewport2D from "@/scripts/viewport/2d/Viewport2D";


import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue-3/dist/bootstrap-vue-3.css'















const app = createApp(App);
app.config.globalProperties.window = window
app.use(store)
app.use(router)
//app.use(BootstrapVue)
app.use(BootstrapVue,{components:{BFormInput,BFormTextarea,BFormCheckbox,BFormSelect}})
app.component('AccordionComponent', AccordionComponent)
app.component('ContainerComponent', ContainerComponent)
app.component('PaletteComponent', PaletteComponent)
app.component('CofigurableButtonComponent', CofigurableButtonComponent)
app.component('CofigurableButtonContent1Component', CofigurableButtonContent1Component)
app.component('CofigurableButtonContent2Component', CofigurableButtonContent2Component)
app.component('CofigurableButtonContent3Component', CofigurableButtonContent3Component)
app.component('ConfigurableButtonsContainerComponent', ConfigurableButtonsContainerComponent)
app.component('CofigurableButtonContent4Component', CofigurableButtonContent4Component)
app.component('CofigurableButtonContent5Component', CofigurableButtonContent5Component)



app.component('FlexContainerComponent', FlexContainerComponent)
app.component('DividerComponent', DividerComponent)
app.component('Header1Component', Header1Component)
app.component('Viewport3D', Viewport3D)
app.component('Viewport2D', Viewport2D)
app.mount("#app");

