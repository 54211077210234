import { useButton1 } from "@/scripts/ui/buttons/Button1";
import { useButton3 } from "@/scripts/ui/buttons/Button3";
import { useButton4 } from "@/scripts/ui/buttons/Button4";
import { useButton5 } from "@/scripts/ui/buttons/Button5";
import { useAccordion2 } from "@/scripts/ui/accordions/Accordion2";
import { useDivider1 } from "@/scripts/ui/dividers/Divider1";
import { useHeader1 } from "@/scripts/ui/headers/Header1";
import { useFlexContainer1 } from "@/scripts/ui/flex_containers/FlexContainer1";
import { useSceneEntities } from "@/stores/SceneEntities";
import { useButtonRadioGroup } from "@/scripts/ui/ButtonRadioGroup";
export function useTriton(injectedRadioGroups) {
  let swdDesignRadioGroup = useButtonRadioGroup();
  const sceneEntities = useSceneEntities();
  const target = useButton3();
  let accents = ["Hex Blue", "B Weave Veigra"];
  let imagePrefix = "Triton";

  target.attributes.componentData.display.label = imagePrefix;
  if (window.isSentrelBuild && !window.isJoyceBuild && window.isDreamAgeBuild) {
    target.attributes.componentData.display.label = "Poseidon";
  }
  target.attributes.componentData.display.thumb =
    "assets/images/cg/sentrel_designs/main/thumbs/" + imagePrefix + "_thumb.jpg";
  let targetChildren = target.attributes.componentData.children;
  target.attributes.componentData.key = "swd_triton";
  target.attributes.componentData.sharedKey = "swd_design";
  sceneEntities.addItemToUIList(target.attributes.componentData);
  target.attributes.componentData.interaction.actions["scene_update"] = true;

  //Divider Door Options--------------------------------------------------
  let divider = useDivider1();
  targetChildren.push(divider);
  divider.attributes.componentData.display.useLeft = true;
  divider.attributes.componentData.display.useRight = true;
  divider.attributes.componentData.display.label = "Design Options";

  //Flex Container--------------------------------------------------
  let flexContainer1 = useFlexContainer1();
  targetChildren.push(flexContainer1);
  let flexContainer1Children = flexContainer1.attributes.componentData.children;

  let designOptionRegular = useButton4();
  designOptionRegular.attributes.componentData.interaction.deselectSelf = false;
  flexContainer1Children.push(designOptionRegular);
  designOptionRegular.attributes.componentData.display.label = "Regular";
  designOptionRegular.attributes.componentData.display.thumb =
    "assets/images/cg/sentrel_designs/main/thumbs/" + imagePrefix + "_thumb.jpg";

  designOptionRegular.attributes.componentData.key = "swd_triton_regular";
  designOptionRegular.attributes.componentData.sharedKey = "swd_design";
  sceneEntities.addItemToUIList(designOptionRegular.attributes.componentData);
  designOptionRegular.attributes.componentData.interaction.radioGroup = swdDesignRadioGroup;
  designOptionRegular.attributes.componentData.interaction.actions["scene_update"] = true;

  let designOptionSim2x2 = useButton4();
  designOptionSim2x2.attributes.componentData.interaction.deselectSelf = false;
  flexContainer1Children.push(designOptionSim2x2);
  designOptionSim2x2.attributes.componentData.display.label = "Sim 2x2";
  designOptionSim2x2.attributes.componentData.display.thumb =
    "assets/images/cg/sentrel_designs/sim/thumbs/" + imagePrefix + "_2x2_thumb.jpg";

  designOptionSim2x2.attributes.componentData.key = "swd_triton_sim2x2";
  designOptionSim2x2.attributes.componentData.sharedKey = "swd_design";
  sceneEntities.addItemToUIList(designOptionSim2x2.attributes.componentData);
  designOptionSim2x2.attributes.componentData.interaction.radioGroup = swdDesignRadioGroup;
  designOptionSim2x2.attributes.componentData.interaction.actions["scene_update"] = true;

  let header1 = useHeader1();
  targetChildren.push(header1);
  header1.attributes.componentData.display.label = "APPLY TO:";

  //Interior Walls--------------------------------------------------
  let interiorWalls = useButton5();
  targetChildren.push(interiorWalls);
  interiorWalls.attributes.componentData.display.label = "Shower Walls";
  interiorWalls.attributes.componentData.key = "swd_triton_interiorwalls";
  interiorWalls.attributes.componentData.sharedKey = "swd_materials";
  sceneEntities.addItemToUIList(interiorWalls.attributes.componentData);
  interiorWalls.attributes.componentData.skipSerialization = true;
  interiorWalls.attributes.componentData.interaction.toggle = false;
  interiorWalls.attributes.componentData.interaction.actions["scene_update"] = true;

 

  //Shower Floor--------------------------------------------------
  let showerFloor = useButton5();
  targetChildren.push(showerFloor);
  showerFloor.attributes.componentData.display.label = "Shower Floor";
  showerFloor.attributes.componentData.key = "swd_triton_showerfloor";
  showerFloor.attributes.componentData.sharedKey = "swd_materials";
  sceneEntities.addItemToUIList(showerFloor.attributes.componentData);
  showerFloor.attributes.componentData.skipSerialization = true;
  showerFloor.attributes.componentData.interaction.toggle = false;
  showerFloor.attributes.componentData.interaction.actions["scene_update"] = true;

  //Ceiling Panel--------------------------------------------------
  let ceilingPanel = useButton5();
  targetChildren.push(ceilingPanel);
  ceilingPanel.attributes.componentData.display.label = "Ceiling Panel";
  ceilingPanel.attributes.componentData.key = "swd_triton_ceilingpanel";
  ceilingPanel.attributes.componentData.sharedKey = "swd_materials";
  sceneEntities.addItemToUIList(ceilingPanel.attributes.componentData);
  ceilingPanel.attributes.componentData.skipSerialization = true;
  ceilingPanel.attributes.componentData.interaction.toggle = false;
  ceilingPanel.attributes.componentData.interaction.actions["scene_update"] = true;

   //Wainscotting--------------------------------------------------
   let wainscotting = useButton5();
   targetChildren.push(wainscotting);
   wainscotting.attributes.componentData.display.label = "Wainscotting";
   wainscotting.attributes.componentData.key = "swd_triton_wainscotting";
   wainscotting.attributes.componentData.sharedKey = "swd_materials";
   sceneEntities.addItemToUIList(wainscotting.attributes.componentData);
   wainscotting.attributes.componentData.skipSerialization = true;
   wainscotting.attributes.componentData.interaction.toggle = false;
   wainscotting.attributes.componentData.interaction.actions["scene_update"] = true;

  //All Surfaces--------------------------------------------------
  let allSurfaces = useButton5();
  targetChildren.push(allSurfaces);
  allSurfaces.attributes.componentData.display.label = "All Surfaces";
  allSurfaces.attributes.componentData.key = "swd_triton_allsurfaces";
  allSurfaces.attributes.componentData.sharedKey = "swd_materials";
  sceneEntities.addItemToUIList(allSurfaces.attributes.componentData);
  allSurfaces.attributes.componentData.skipSerialization = true;
  allSurfaces.attributes.componentData.interaction.toggle = false;
  allSurfaces.attributes.componentData.interaction.actions["scene_update"] = true;

  allSurfaces.attributes.componentData.interaction.stateless = true;
  interiorWalls.attributes.componentData.interaction.stateless = true;
  wainscotting.attributes.componentData.interaction.stateless = true;
  showerFloor.attributes.componentData.interaction.stateless = true;
  ceilingPanel.attributes.componentData.interaction.stateless = true;

  //Divider Accent Options--------------------------------------------------
  divider = useDivider1();
  targetChildren.push(divider);
  divider.attributes.componentData.display.useLeft = true;
  divider.attributes.componentData.display.useRight = true;
  divider.attributes.componentData.display.label = "Accent Options";

  //Accent Trim--------------------------------------------------
  let accentTrim = useAccordion2();
  targetChildren.push(accentTrim);
  accentTrim.attributes.componentData.display.label = "Accent Trim";

  accentTrim.attributes.componentData.key = "swd_triton_accenttrim";
  accentTrim.attributes.componentData.sharedKey = "accents_assets";
  sceneEntities.addItemToUIList(accentTrim.attributes.componentData);
  accentTrim.attributes.componentData.interaction.radioGroup = injectedRadioGroups.swdAccentEnableRadioGroup;
  accentTrim.attributes.componentData.interaction.actions["scene_update"] = true;

  let accentKeys = [];
  for (let i = 0; i < accents.length; i++) {
    let accent = useButton1();
    let accentkey1 = accents[i].split(" ").join("").toLowerCase();
    let accentkey2 = target.attributes.componentData.key + "_" + accentkey1;
    accentTrim.attributes.componentData.addChild(accent);
    accent.attributes.componentData.display.label = accents[i];
    accent.attributes.componentData.interaction.deselectSelf = false;
    accentKeys.push(accentkey2);
    accent.attributes.componentData.key = accentkey2;
    accent.attributes.componentData.sharedKey = "accents_materials";
    sceneEntities.addItemToUIList(accent.attributes.componentData);
    accent.attributes.componentData.interaction.radioGroup = injectedRadioGroups.swdAccentSelectRadioGroup;
    accent.attributes.componentData.interaction.actions["scene_update"] = true;
    accent.attributes.componentData.parentEnabledOverridesChildEnabled = true;
  }
  accentTrim.attributes.componentData.additionalTriggersSoft = accentKeys;

  return target;
}
