
export function useComponentStylingType10() {
   
    const data = {
        classStringContainerDefault:"container-type-6 py-2 my-1",
        classStringContainerSelected:"container-type-6 py-2 my-1",
        classStringTextDefault:"text-type-2 text-color-6",
        classStringTextSelected:"text-type-2 text-color-6",
        classStringIconDefault:"mdi icon icon-color-6 mdi-pencil",
        classStringIconSelected:"mdi icon icon-color-7 mdi-pencil",
       
          };

 
  return data;
}