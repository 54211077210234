<template class="w-100 h-100">
  <div class="h-100 d-flex flex-column">
    <b-img
    v-if="!window.isWhiteLabelBuild"
      class="mb-4 d-block mx-auto"
      width="200px"
      :src="getBrandingImage"
    ></b-img>
    <div
      ref="snc"
      style="overflow-y: auto;padding-right: 10px;position: relative;scroll-behavior: smooth;"
    >
      <component
        v-for="(item, index) in uiBuildData"
        :key="'sidenavitem_' + index"
        :is="item.component"
        v-bind="item.attributes"
        :keyref="'sidenavitem_' + index"
        @select="onSelect"
      >
      </component>
    </div>
  </div>
</template>

<script setup>
import { useRequests } from "@/stores/Requests";
import { useTheme } from "@/stores/Theme";
import { useSceneEntities } from "@/stores/SceneEntities";
import { ref, nextTick, computed } from "vue";
import EventNames from "@/scripts/events/EventNames";
import PublishSubscribe from "@/scripts/events/PublishSubscribe";
const sceneEntities = useSceneEntities();
const snc = ref(null);
const requests = useRequests();
const theme = useTheme();

const uiBuildData = computed(()=>{return sceneEntities.uiBuildData});
const publishSubscribe = new PublishSubscribe();

function onSelect(payload, event) {
  publishSubscribe.dispatchEventGlobal(EventNames.MENU_INTERACTION,payload);  
  invalidateScroll(event);
}

const getBrandingImage = computed(() => {
  let path = null;
  if (theme.themeKey === "light") {
    //fivestar
    if (!window.isWhiteLabelBuild && !window.isSentrelBuild && !window.isJoyceBuild && !window.isDreamAgeBuild) {
      path = window.location_url + "assets/images/menu_logo_1_light.png";
    }
    //sentrel
    if (!window.isWhiteLabelBuild && window.isSentrelBuild && !window.isJoyceBuild && !window.isDreamAgeBuild) {
      path = window.location_url + "assets/images/menu_logo_1_sentrel_light.png";
    }
    //joyce
    if (!window.isWhiteLabelBuild && window.isSentrelBuild && window.isJoyceBuild && !window.isDreamAgeBuild) {
      path = window.location_url + "assets/images/menu_logo_1_joyce_light.png";
    }
    //dreamage
    if (!window.isWhiteLabelBuild && window.isSentrelBuild && !window.isJoyceBuild && window.isDreamAgeBuild) {
      path = window.location_url + "assets/images/menu_logo_1_dreamage_light.png";
    }
  } else {
    //just asume dark as there are only two themes

    //fivestar
    if (!window.isWhiteLabelBuild && !window.isSentrelBuild && !window.isJoyceBuild && !window.isDreamAgeBuild) {
      path = window.location_url + "assets/images/menu_logo_1_dark.png";
    }
    //sentrel
    if (!window.isWhiteLabelBuild && window.isSentrelBuild && !window.isJoyceBuild && !window.isDreamAgeBuild) {
      path = window.location_url + "assets/images/menu_logo_1_sentrel_dark.png";
    }

    //joyce
    if (!window.isWhiteLabelBuild && window.isSentrelBuild && window.isJoyceBuild && !window.isDreamAgeBuild) {
      path = window.location_url + "assets/images/menu_logo_1_joyce_dark.png";
    }

    //dreamage
    if (!window.isWhiteLabelBuild && window.isSentrelBuild && !window.isJoyceBuild && window.isDreamAgeBuild) {
      path = window.location_url + "assets/images/menu_logo_1_dreamage_dark.png";
    }
  }

  return path;
});

async function invalidateScroll(event) {
  
  // since setting dom element scroll to smooth , math of positions broke due to interpolation over time
  //using next tick seems to be enough for it to get the correct values
  //keep an eye on it , if it fails again .. then scroll-behavior: smooth; needs to be removed.
  await nextTick();
  let scrollDistance = 0;
  let target = event.currentTarget;
  while (target) {
    scrollDistance += target.offsetTop;

    target = target.offsetParent;
    if (target === snc.value) {
      break;
    }
  }

  snc.value.scrollTo(0, scrollDistance);
}
</script>

<style></style>
