import { useSceneEntities } from "@/stores/SceneEntities";
import { usePresetsBuild } from "@/scripts/rooms/actions/general/PresetsBuild";

export function usePresetSave(payload, viewport) {
  const sceneEntities = useSceneEntities();
  let presetCurrentUI = sceneEntities.getUIData(payload.key);

  let preset = sceneEntities.presetData.persistant.find((element) => element.key === payload.key);
  if (!preset) {
    preset = sceneEntities.presetData.default.find((element) => element.key === payload.key);
  }
  if (!preset) {
    preset = {};
    preset.key = payload.key;   
    preset.storageKey = "persistant";
  }
  preset.label = presetCurrentUI.display.label;
  preset.data = null;
  sceneEntities.addPreset(preset);
  sceneEntities.savePresetsPersistant();
  usePresetsBuild();
}
