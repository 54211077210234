import { useAccordion1 } from "@/scripts/ui/accordions/Accordion1";
import { useAccordion2 } from "@/scripts/ui/accordions/Accordion2";
import { useDivider1 } from "@/scripts/ui/dividers/Divider1";
import { useButton4 } from "@/scripts/ui/buttons/Button4";
import { useButton2 } from "@/scripts/ui/buttons/Button2";
import { useButton1 } from "@/scripts/ui/buttons/Button1";
import { useFlexContainer1 } from "@/scripts/ui/flex_containers/FlexContainer1";
import { useButtonRadioGroup } from "@/scripts/ui/ButtonRadioGroup";
import { useSceneEntities } from "@/stores/SceneEntities";
export function useShowerEnclosure() {
  let glassRadioGroup = useButtonRadioGroup();
  let enclosureRadioGroup = useButtonRadioGroup();
  let finishOptionRadioGroup = useButtonRadioGroup();
  const sceneEntities = useSceneEntities();

  let target = useAccordion1();
  target.attributes.componentData.display.label = "Shower Enclosure";
  target.attributes.componentData.key = "showerenclosure";
  sceneEntities.addItemToUIList(target.attributes.componentData);
  let targetChildren = target.attributes.componentData.children;

  let doors = useAccordion2();
  targetChildren.push(doors);
  doors.attributes.componentData.display.label = "Doors";
  let doorsChildren = doors.attributes.componentData.children;
  doors.attributes.componentData.key = "showerenclosure_doors";
  sceneEntities.addItemToUIList(doors.attributes.componentData);

  let biPass = useAccordion2();
  doorsChildren.push(biPass);
  biPass.attributes.componentData.display.label = "Bi-Pass";
  let biPassChildren = biPass.attributes.componentData.children;
  biPass.attributes.componentData.key = "doors_bipass";
  sceneEntities.addItemToUIList(biPass.attributes.componentData);
  biPass.attributes.componentData.interaction.actions["scene_update"] = true;

  //biPassBasco--------------------------------------------------
  let biPassBasco = useButton1();
  biPassChildren.push(biPassBasco);
  biPassBasco.attributes.componentData.key = "biPassBasco";
  sceneEntities.addItemToUIList(biPassBasco.attributes.componentData);
  biPassBasco.attributes.componentData.display.label = "2 Towel";
  biPassBasco.attributes.componentData.interaction.radioGroup = enclosureRadioGroup;
  biPassBasco.attributes.componentData.interaction.actions["scene_update"] = true;

  //biPassOriginal--------------------------------------------------
  let biPassOriginal = useButton1();
  biPassChildren.push(biPassOriginal);
  biPassOriginal.attributes.componentData.key = "biPassOriginal";
  sceneEntities.addItemToUIList(biPassOriginal.attributes.componentData);
  biPassOriginal.attributes.componentData.display.label = "1 Towel";
  biPassOriginal.attributes.componentData.interaction.radioGroup = enclosureRadioGroup;
  biPassOriginal.attributes.componentData.interaction.actions["scene_update"] = true;

  let tuscon = useButton1();
  doorsChildren.push(tuscon);
  tuscon.attributes.componentData.display.label = window.isSentrelBuild ? "Hinge + 1 Panel" : "Hinge + 1 Panel";
  tuscon.attributes.componentData.interaction.radioGroup = enclosureRadioGroup;
  tuscon.attributes.componentData.key = "showerenclosure_doors_tuscon";
  sceneEntities.addItemToUIList(tuscon.attributes.componentData);
  tuscon.attributes.componentData.interaction.actions["scene_update"] = true;

  let rotoloBarn = useButton1();
  doorsChildren.push(rotoloBarn);
  rotoloBarn.attributes.componentData.display.label = window.isSentrelBuild ? "Barn Style" : "Barn Style";
  rotoloBarn.attributes.componentData.interaction.radioGroup = enclosureRadioGroup;
  rotoloBarn.attributes.componentData.key = "showerenclosure_doors_rotolo_barn";
  sceneEntities.addItemToUIList(rotoloBarn.attributes.componentData);
  rotoloBarn.attributes.componentData.interaction.actions["scene_update"] = true;

  // infinity hinge
  let infinityHinge = useButton1();
  doorsChildren.push(infinityHinge);
  infinityHinge.attributes.componentData.display.label = window.isSentrelBuild ? "Hinge door" : "Infinity Hinge Door ";
  infinityHinge.attributes.componentData.interaction.radioGroup = enclosureRadioGroup;
  infinityHinge.attributes.componentData.key = "infinity_hinge";
  sceneEntities.addItemToUIList(infinityHinge.attributes.componentData);
  infinityHinge.attributes.componentData.interaction.actions["scene_update"] = true;

  //Divider Door Options--------------------------------------------------
  let divider = useDivider1();
  doorsChildren.push(divider);
  divider.attributes.componentData.display.useLeft = true;
  divider.attributes.componentData.display.useRight = true;
  divider.attributes.componentData.display.label = "Door Options";
  divider.attributes.componentData.key = "divider_alcove_door_options";
  sceneEntities.addItemToUIList(divider.attributes.componentData);

  //openclose--------------------------------------------------
  let openclose = useButton2();
  doorsChildren.push(openclose);
  openclose.attributes.componentData.display.label = "Open/Close";
  openclose.attributes.componentData.key = "shower_doors_open";
  sceneEntities.addItemToUIList(openclose.attributes.componentData);
  openclose.attributes.componentData.interaction.actions["scene_update"] = true;

  //showerDoorsInvert--------------------------------------------------
  let showerDoorsInvert = useButton1();
  doorsChildren.push(showerDoorsInvert);
  showerDoorsInvert.attributes.componentData.key = "shower_doors_invert";
  sceneEntities.addItemToUIList(showerDoorsInvert.attributes.componentData);
  showerDoorsInvert.attributes.componentData.display.label = "Invert";
  showerDoorsInvert.attributes.componentData.interaction.actions["scene_update"] = true;

  //glass--------------------------------------------------
  divider = useDivider1();
  doorsChildren.push(divider);
  divider.attributes.componentData.display.useLeft = true;
  divider.attributes.componentData.display.useRight = true;
  divider.attributes.componentData.display.label = "Glass";
  divider.attributes.componentData.key = "divider_shower_glass";
  sceneEntities.addItemToUIList(divider.attributes.componentData);

  //Flex Container--------------------------------------------------
  let flexContainer1 = useFlexContainer1();
  doorsChildren.push(flexContainer1);
  let flexContainer1Children = flexContainer1.attributes.componentData.children;

  let option = useButton4();
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "Clear";
  option.attributes.componentData.display.thumb = "assets/images/cg/glass/glass_clear_thumb.png";
  option.attributes.componentData.interaction.radioGroup = glassRadioGroup;
  option.attributes.componentData.key = "showerenclosure_glass_clear";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.actions["scene_update"] = true;

  option = useButton4();
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "Obscure";
  option.attributes.componentData.display.thumb = "assets/images/cg/glass/glass_obscure_thumb.png";
  option.attributes.componentData.interaction.radioGroup = glassRadioGroup;
  option.attributes.componentData.key = "showerenclosure_glass_obscure";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.actions["scene_update"] = true;

  option = useButton4();
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "Rain";
  option.attributes.componentData.display.thumb = "assets/images/cg/glass/glass_rain_thumb.png";
  option.attributes.componentData.interaction.radioGroup = glassRadioGroup;
  option.attributes.componentData.key = "showerenclosure_glass_rain";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.actions["scene_update"] = true;

  let curtains = useAccordion2();
  targetChildren.push(curtains);
  curtains.attributes.componentData.display.label = "Curtains";
  let curtainsChildren = curtains.attributes.componentData.children;
  curtains.attributes.componentData.key = "showerenclosure_curtains";
  sceneEntities.addItemToUIList(curtains.attributes.componentData);

  let straight = useButton2();
  curtainsChildren.push(straight);
  straight.attributes.componentData.display.label = "Straight";
  straight.attributes.componentData.interaction.radioGroup = enclosureRadioGroup;
  straight.attributes.componentData.key = "showerenclosure_straight";
  sceneEntities.addItemToUIList(straight.attributes.componentData);
  straight.attributes.componentData.interaction.actions["scene_update"] = true;

  let curved = useButton2();
  curtainsChildren.push(curved);
  curved.attributes.componentData.display.label = "Curved";
  curved.attributes.componentData.interaction.radioGroup = enclosureRadioGroup;
  curved.attributes.componentData.key = "showerenclosure_curved";
  sceneEntities.addItemToUIList(curved.attributes.componentData);
  curved.attributes.componentData.interaction.actions["scene_update"] = true;

  //Finish Options--------------------------------------------------
  divider = useDivider1();
  targetChildren.push(divider);
  divider.attributes.componentData.display.useLeft = true;
  divider.attributes.componentData.display.useRight = true;
  divider.attributes.componentData.display.label = "Finish Options";
  divider.attributes.componentData.key = "divider_shower_finish";
  sceneEntities.addItemToUIList(divider.attributes.componentData);

  //Flex Container--------------------------------------------------
  flexContainer1 = useFlexContainer1();
  targetChildren.push(flexContainer1);
  flexContainer1Children = flexContainer1.attributes.componentData.children;

  option = useButton4();
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "Chrome";
  option.attributes.componentData.display.color = "#b5bbbd";
  option.attributes.componentData.interaction.radioGroup = finishOptionRadioGroup;
  option.attributes.componentData.key = "metal_shared_1_chrome";
  option.attributes.componentData.colorKey = "chrome";
  option.attributes.componentData.sharedKey = "allmetals";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;

  option = useButton4();
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "Brushed Nickle";
  option.attributes.componentData.display.color = "#aaa9a6";
  option.attributes.componentData.interaction.radioGroup = finishOptionRadioGroup;
  option.attributes.componentData.key = "metal_shared_1_brushed_nickel";
  option.attributes.componentData.colorKey = "brushed_nickel";
  option.attributes.componentData.sharedKey = "allmetals";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;

  option = useButton4();
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "Oil Bronze";
  option.attributes.componentData.display.color = "#554943";
  option.attributes.componentData.interaction.radioGroup = finishOptionRadioGroup;
  option.attributes.componentData.key = "metal_shared_1_oil_bronze";
  option.attributes.componentData.colorKey = "oil_bronze";
  option.attributes.componentData.sharedKey = "allmetals";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;

  option = useButton4();
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "Matt Black";
  option.attributes.componentData.display.color = "#282828";
  option.attributes.componentData.interaction.radioGroup = finishOptionRadioGroup;
  option.attributes.componentData.key = "metal_shared_1_black";
  option.attributes.componentData.colorKey = "black";
  option.attributes.componentData.sharedKey = "allmetals";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;

  return target;
}
