import { useSceneEntities } from "@/stores/SceneEntities";

export function useAssetFaucett17464iSideRight(payload, viewport) {
    const sceneEntities = useSceneEntities();

    
    let t17494i_sidebarright = sceneEntities.getUIData("t17494i_sidebarright");
    let t17494i = sceneEntities.getUIData("t17494i");

    let t17464i_head_slide_right = sceneEntities.configuratorView.viewportComponent.getViewportAsset("t17464i_head_slide_right");
    t17464i_head_slide_right.disabled = payload.disabled;
    t17464i_head_slide_right.enabled = payload.enabled;
    viewport.setEnabled(t17464i_head_slide_right);

    let head_slide_hose_left = sceneEntities.configuratorView.viewportComponent.getViewportAsset("head_slide_hose_right");
    head_slide_hose_left.disabled = payload.disabled;
    head_slide_hose_left.enabled = payload.enabled || (t17494i_sidebarright.enabled && t17494i.enabled);
    viewport.setEnabled(head_slide_hose_left);

  

}