<template>
  <div id="content-container" class="d-flex flex-row-reverse">
    <div id="content" class="d-flex flex-column bg-color-1">
      <!-- nav bar and side nav -->
      <NavBarCustomComponent
        @togglemenu="toggleMenu"
        @showsettings="showSettings"
        @showSaveSettings="showSaveSettings"
      />

      <div class="content-bg-1" style="flex-basis: 100%; overflow-y: hidden; position: relative">
        <div
          v-show="showSettingsModel"
          class="pr-1 py-2 w-100 h-100"
          style="position: absolute; z-index: 10; overflow-y: auto;    background-color: #000000c7;"
        >
        <div
            class="pe-2 fs-2 p-0 m-0 content-bg-9"
            data-v-2473d24b=""
            style="
              white-space: nowrap;
              border-bottom: 1px solid rgb(76, 76, 76);
              margin-bottom: 10px !important;
              text-align: right;
            "
          >
            <div class="fw-bold text-color-1-inverted p-0 m-0">Settings</div>
          </div>

          <NavigationSettingsComponent
            @resetcamera="resetCamera"
            @changerenderindex="changeRenderIndex"
          ></NavigationSettingsComponent>
        </div>

        <div
          v-show="showSaveSettingsModel"
          class="pr-1 py-2 w-100 h-100"
          style="position: absolute; z-index: 10; overflow-y: auto;    background-color: #000000c7;"
        >
          <div
            class="pe-2 fs-2 p-0 m-0 content-bg-9"
            data-v-2473d24b=""
            style="
              white-space: nowrap;
              border-bottom: 1px solid rgb(76, 76, 76);
              margin-bottom: 10px !important;
              text-align: right;
            "
          >
            <div class="fw-bold text-color-1-inverted p-0 m-0">Saves</div>
          </div>
          <NavigationPresetsComponent></NavigationPresetsComponent>
        </div>

        <!-- vue-router -->
        <router-view></router-view>
      </div>
      <div id="content-cover"></div>
    </div>
    <div id="side-nav" class="p-2">
      <SideNavComponent></SideNavComponent>
    </div>

    <div id="displace-nav"></div>
  </div>
</template>

<script setup>
import NavBarCustomComponent from "@/components/NavBarCustomComponent";
import NavigationSettingsComponent from "@/components/NavigationSettingsComponent";
import NavigationPresetsComponent from "@/components/NavigationPresetsComponent";

import { useSceneEntities } from "@/stores/SceneEntities";
import { ref, watch, onMounted, onUnmounted, nextTick, computed } from "vue";
import { useScreen } from "vue-screen";
import { useIsSmallerScreens } from "@/scripts/composables/IsSmallerScreens";
import SideNavComponent from "@/components/SideNavComponent";
import { gsap } from "gsap";
import PublishSubscribe from "@/scripts/events/PublishSubscribe";
import EventNames from "@/scripts/events/EventNames";
const publishSubscribe = new PublishSubscribe();
const isSmallerScreens = useIsSmallerScreens();
const content = ref(null);
const contentCover = ref(null);
const contentContainer = ref(null);
const sideNav = ref(null);
const displaceNav = ref(null);
const isNavDisplaying = ref(true);

const showSettingsModel = ref(false);
const showSaveSettingsModel = ref(false);
const sceneEntities = useSceneEntities();
var displacement = isSmallerScreens.value ? 300 : 384;
const screen = useScreen();

var onPresetApplyListener = onPresetApply.bind(this);
publishSubscribe.addEventListenerGlobal(EventNames.PRESET_APPLY, onPresetApplyListener);
function onPresetApply() {
  showSaveSettingsModel.value = false;
  showSettingsModel.value = false;
}
onUnmounted(() => {
  publishSubscribe.removeEventListenerGlobal(EventNames.PRESET_APPLY, onPresetApplyListener);
});
onMounted(() => {
  content.value = document.getElementById("content");

  contentCover.value = document.getElementById("content-cover");
  contentCover.value.addEventListener("click", () => {
    toggleMenu();
  });
  contentCover.value.style.display = "none";

  contentContainer.value = document.getElementById("content-container");
  sideNav.value = document.getElementById("side-nav");
  displaceNav.value = document.getElementById("displace-nav");
  invalidateDisplay();
  window.onresize = calculateWidth;
  sceneEntities.configuratorView.build();
});
watch(screen, invalidateDisplay);

async function invalidateDisplay() {
  //clear container width to not affect recalculation on next frame
  contentContainer.value.style.width = 0;
  await nextTick();
  calculateWidth();
  calculateLayout();
}

function calculateWidth() {
  let width = window.innerWidth;
  let val = parseInt(width * 0.2);
  val = Math.min(val, 370);
  val = Math.max(val, 300);
  displacement = isSmallerScreens.value ? 300 : val;
  contentContainer.value.style.width = width + displacement + "px";
  contentContainer.value.style.position = "absolute";
  contentContainer.value.style.height = "100%";
  contentContainer.value.style.left = -displacement + "px";

  //reset width of  nav
  sideNav.value.style.width = displacement + "px";
}

function calculateLayout() {
  gsap.killTweensOf(sideNav.value);
  gsap.killTweensOf(displaceNav.value);
  if (isSmallerScreens.value) {
    //if screen changes default to menu hidden
    isNavDisplaying.value = false;
    //pop side nav from layout
    sideNav.value.style.position = "absolute";
    sideNav.value.style.height = "100%";
    sideNav.value.style.left = 0;

    //reset width of displace nav
    displaceNav.value.style.width = displacement + "px";
  } else {
    //hide contentCover
    contentCover.value.style.display = "none";
    //if screen changes default to menu shown
    isNavDisplaying.value = true;
    //put nav back in layout
    sideNav.value.style.position = "relative";
    sideNav.value.style.left = "unset";
    //reset width of displace nav
    displaceNav.value.style.width = displacement + "px";
  }
}
function resetCamera() {
  publishSubscribe.dispatchEventGlobal(EventNames.RESET_CAMERA);
}
function changeRenderIndex() {
  publishSubscribe.dispatchEventGlobal(EventNames.TOGGLE_HIGH_RENDERING);
}

function showSettings() {
  showSettingsModel.value = !showSettingsModel.value;
  showSaveSettingsModel.value = false;
}
function showSaveSettings() {
  showSaveSettingsModel.value = !showSaveSettingsModel.value;
  showSettingsModel.value = false;
}

function toggleMenu() {
  showSettingsModel.value = false;
  showSaveSettingsModel.value = false;

  isNavDisplaying.value = !isNavDisplaying.value;
  let val = displacement;
  let duration = 0.28;
  if (!isNavDisplaying.value) {
    val = 0;
  }
  if (!isSmallerScreens.value) {
    publishSubscribe.dispatchEventGlobal(EventNames.CONTENT_SIZING_BEGIN);
    gsap.to(displaceNav.value, {
      duration: duration,
      width: val,
      onComplete: () => {
        publishSubscribe.dispatchEventGlobal(EventNames.CONTENT_SIZING_COMPLETE);
      },
    });
  } else {
    gsap.to(sideNav.value, { duration: duration, left: val });
    if (isNavDisplaying.value) {
      contentCover.value.style.display = "block";
    } else {
      contentCover.value.style.display = "none";
    }
  }
}
</script>

<style scoped>
#content {
  position: relative;
  flex-basis: 100%;
}
#content-container {
  overflow: hidden;
}
#content-cover {
  width: 100%;
  height: 100%;
  position: absolute;
  background-color: #00000073;
}
</style>
