import { useButton1 } from "@/scripts/ui/buttons/Button1";
import { useButton2 } from "@/scripts/ui/buttons/Button2";
import { useAccordion1 } from "@/scripts/ui/accordions/Accordion1";
import { useAccordion2 } from "@/scripts/ui/accordions/Accordion2";
import { useDivider1 } from "@/scripts/ui/dividers/Divider1";
import { useSceneEntities } from "@/stores/SceneEntities";
import { useButtonRadioGroup } from "@/scripts/ui/ButtonRadioGroup";
export function useRoom() {
  const sceneEntities = useSceneEntities();
  let wallsRadioGroup = useButtonRadioGroup();

  let target = useAccordion1();
  target.attributes.componentData.display.label = "Room";
  target.attributes.componentData.key = "room";
  sceneEntities.addItemToUIList(target.attributes.componentData);

  //Wainscotting--------------------------------------------------
  let wainscotting = useButton1();
  target.attributes.componentData.addChild(wainscotting);
  wainscotting.attributes.componentData.display.label = "Wainscotting";
  wainscotting.attributes.componentData.key = "wainscotting";
  sceneEntities.addItemToUIList(wainscotting.attributes.componentData);
  wainscotting.attributes.componentData.interaction.actions["scene_update"] = true;

  //Invert Orientation--------------------------------------------------
  let invertOrientation = useButton1();
  target.attributes.componentData.addChild(invertOrientation);
  invertOrientation.attributes.componentData.key = "invertorientation";
  sceneEntities.addItemToUIList(invertOrientation.attributes.componentData);
  invertOrientation.attributes.componentData.display.label = "Invert Orientation";
  invertOrientation.attributes.componentData.interaction.actions["scene_update"] = true;

  //divider--------------------------------------------------
  let divider = useDivider1();

  target.attributes.componentData.addChild(divider);
  divider.attributes.componentData.display.useLeft = true;
  divider.attributes.componentData.display.useRight = true;

  //Walls Full--------------------------------------------------
  let wallsFull = useButton2();

  target.attributes.componentData.addChild(wallsFull);
  wallsFull.attributes.componentData.key = "wallsfull";
  sceneEntities.addItemToUIList(wallsFull.attributes.componentData);
  wallsFull.attributes.componentData.interaction.radioGroup = wallsRadioGroup;
  wallsFull.attributes.componentData.display.label = "Walls Full";
  wallsFull.attributes.componentData.interaction.deselectSelf = false;
  wallsFull.attributes.componentData.interaction.actions["scene_update"] = true;

  //Walls 3/4--------------------------------------------------
  let walls34 = useAccordion2();

  target.attributes.componentData.addChild(walls34);
  walls34.attributes.componentData.key = "walls34";
  sceneEntities.addItemToUIList(walls34.attributes.componentData);
  walls34.attributes.componentData.interaction.radioGroup = wallsRadioGroup;
  walls34.attributes.componentData.display.label = "Walls 3/4";
  walls34.attributes.componentData.interaction.deselectSelf = false;
  let walls34Children = walls34.attributes.componentData.children;
  walls34.attributes.componentData.interaction.actions["scene_update"] = true;

  //Soffit--------------------------------------------------
  let soffit = useButton1();
  walls34.attributes.componentData.addChild(soffit);
  soffit.attributes.componentData.key = "soffit";
  sceneEntities.addItemToUIList(soffit.attributes.componentData);
  soffit.attributes.componentData.display.label = "Soffit";
  soffit.attributes.componentData.interaction.actions["scene_update"] = true;
  soffit.attributes.componentData.parentEnabledOverridesChildEnabled = true;
  return target;
}
