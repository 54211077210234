import { useButtonInteraction } from "@/scripts/ui/ButtonInteraction";
import { useComponentDisplayData } from "@/scripts/ui/ComponentDisplayData";
import { useComponentData } from "@/scripts/ui/ComponentData";
import { useComponentStylingType4 } from "@/scripts/ui/ComponentStylingType4";
export function useButton3() {
  let data = {};
  data.component = "AccordionComponent";
  let attr = (data.attributes = {});
  attr.componentData = useComponentData();
  attr.componentData.content = "CofigurableButtonContent1Component";
  attr.componentData.styling = useComponentStylingType4();
  attr.componentData.interaction = useButtonInteraction();
  attr.componentData.display = useComponentDisplayData();
  attr.componentData.children = [];

  return data;
}
