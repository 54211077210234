import { useSceneEntities } from "@/stores/SceneEntities";
import { useUIRadioGroup } from "@/scripts/rooms/actions/general/UIRadioGroup";
import { useAssetGrabbarsValidate } from "@/scripts/rooms/actions/alcove/AssetGrabbarsValidate";

import { useAssetRecessSoap1 } from "@/scripts/rooms/actions/alcove/AssetRecessSoap1";
import { useAssetRecessSoap2 } from "@/scripts/rooms/actions/alcove/AssetRecessSoap2";
import { useAssetRecessSoap1Window } from "@/scripts/rooms/actions/alcove/AssetRecessSoap1Window";
import { useAssetRecessSoap2Window } from "@/scripts/rooms/actions/alcove/AssetRecessSoap2Window";
import { useAssetRecessSteelShelf1 } from "@/scripts/rooms/actions/alcove/AssetRecessSteelShelf1";
import { useAssetRecessSteelShelf2 } from "@/scripts/rooms/actions/alcove/AssetRecessSteelShelf2";
import { useAssetRecessCustomSoap1 } from "@/scripts/rooms/actions/alcove/AssetRecessCustomSoap1";
import { useAssetRecessCustomSoap2 } from "@/scripts/rooms/actions/alcove/AssetRecessCustomSoap2";

export function useAssetRecessValidate2(payload, viewport) {
  const sceneEntities = useSceneEntities();

  let tub2852 = sceneEntities.getUIData("walkin2852");
  let tub2653 = sceneEntities.getUIData("walkin2653");
  let tub3053 = sceneEntities.getUIData("walkin3053");
  let mustDisable = false;
  if (tub2852.enabled || tub2653.enabled || tub3053.enabled) {
    mustDisable = true;
  }

  if (mustDisable) {
    let accessories_recess_regular = sceneEntities.getUIData("accessories_recess_regular").enabled;
    let accessories_recess_window = sceneEntities.getUIData("accessories_recess_window").enabled;
    

    //if both are false it means another wall type is currently active and this needs to be changed to a valid one
    if (!accessories_recess_regular && !accessories_recess_window) {
      let wallsfull = sceneEntities.getUIData("wallsfull").enabled;
      let walls34 = sceneEntities.getUIData("walls34").enabled;

      //set ui to regular
      let data = sceneEntities.getUIData("accessories_recess_regular");
      data.enabled = true;
      let previousSelection = useUIRadioGroup(data);

      //call current wall action so it is all disabled

      let interactionFunctions = {};
      //recess soap 1
      interactionFunctions.accessories_recess_soap1 = useAssetRecessSoap1;
      //recess soap 2
      interactionFunctions.accessories_recess_soap2 = useAssetRecessSoap2;
      //recess soap 1 window
      interactionFunctions.accessories_recess_soap1window = useAssetRecessSoap1Window;
      //recess soap 2 window
      interactionFunctions.accessories_recess_soap2window = useAssetRecessSoap2Window;
      //recess steel shelf 1
      interactionFunctions.accessories_recess_steelshelf1 = useAssetRecessSteelShelf1;
      //recess steel shelf 2
      interactionFunctions.accessories_recess_steelshelf2 = useAssetRecessSteelShelf2;
      //recess custom soap 1
      interactionFunctions.accessories_recess_custom_soap_1 = useAssetRecessCustomSoap1;
      //recess custom soap 2
      interactionFunctions.accessories_recess_custom_soap_2 = useAssetRecessCustomSoap2;
      if (previousSelection) {
        let f = interactionFunctions[previousSelection.key];
        if (f) {
          f(previousSelection, viewport);
        }
      }

      let wall1 = sceneEntities.configuratorView.viewportComponent.getViewportAsset("wall_interior_center_full");
      let wall2 = sceneEntities.configuratorView.viewportComponent.getViewportAsset("wall_interior_center_34");
      if (wallsfull) {
        wall1.enabled = true;
        viewport.setEnabled(wall1);

        wall2.enabled = false;
        viewport.setEnabled(wall2);
      }
      if (walls34) {
        wall1.enabled = false;
        viewport.setEnabled(wall1);

        wall2.enabled = true;
        viewport.setEnabled(wall2);
      }
    }
  }

  let accessories_recess_soap1 = sceneEntities.getUIData("accessories_recess_soap1");
  let accessories_recess_soap2 = sceneEntities.getUIData("accessories_recess_soap2");
  let accessories_recess_soap1window = sceneEntities.getUIData("accessories_recess_soap1window");
  let accessories_recess_soap2window = sceneEntities.getUIData("accessories_recess_soap2window");
  let accessories_recess_steelshelf1 = sceneEntities.getUIData("accessories_recess_steelshelf1");
  let accessories_recess_steelshelf2 = sceneEntities.getUIData("accessories_recess_steelshelf2");
  let accessories_recess_custom_soap_1 = sceneEntities.getUIData("accessories_recess_custom_soap_1");
  let accessories_recess_custom_soap_2 = sceneEntities.getUIData("accessories_recess_custom_soap_2");

  if(accessories_recess_soap1){
    accessories_recess_soap1.disabled = mustDisable;
  }
  if(accessories_recess_soap2){
    accessories_recess_soap2.disabled = mustDisable;
  }
  if(accessories_recess_soap1window){
    accessories_recess_soap1window.disabled = mustDisable;
  }
  if(accessories_recess_soap2window){
    accessories_recess_soap2window.disabled = mustDisable;
  }
  if(accessories_recess_steelshelf1){
    accessories_recess_steelshelf1.disabled = mustDisable;
  }
  if(accessories_recess_steelshelf2){
    accessories_recess_steelshelf2.disabled = mustDisable;
  }
  if(accessories_recess_custom_soap_1){
    accessories_recess_custom_soap_1.disabled = mustDisable;
  }
  if(accessories_recess_custom_soap_2){
    accessories_recess_custom_soap_2.disabled = mustDisable;
  }
  

  useAssetGrabbarsValidate(null,viewport)
}
