import { useAssetPreloader } from "@/stores/AssetPreloader";
export default class BabylonSceneAsset {
  constructor(key) {
    this.key = key;
    this.node = null;
    this.url = null;
    this.loading = false;
    this.loaded = false;
    this.loadPercent = 0;
    this.enabled = false;
    this.assetPreloader = null;
    this.collectionTransforms = null;
    this.transformKeyCurrent = null;
    this.transformKeyDefault = 'default';
    this.transformValuesWeak = false;
    this.lengthComputable = false;
    this.syncLoading = false;
    this.isExternalAsset = false;

  }
  dispose(){    
    this.node = null;
    this.loading = false;
    this.loaded = false;
    this.loadPercent = 0;
    this.enabled = false;
    this.assetPreloader = null;
    this.collectionTransforms = null;
    this.transformKeyCurrent = null;
    this.transformKeyDefault = null;
    this.transformValuesWeak = false;
    this.lengthComputable = false;

  }
  until(predFn) {
    const poll = (done) => {      
      predFn() ? done() : setTimeout(() => poll(done), 100)
    
    
    };
    return new Promise(poll);
  }
  async loadingPromise(){
    return  await this.until(() => {return this.loaded === true })
  }
  load() {
    this.assetPreloader = useAssetPreloader();
    this.loading = true;
    this.assetPreloader.load(this);
  }

  loadProgress(event) {
    if (event) {
      this.lengthComputable = event.lengthComputable;
      if (event.lengthComputable) {       
        this.loadPercent = event.loaded / event.total;       
      }
      this.assetPreloader.progress();
    }
  }

  loadSuccess(event) {
    
    this.loading = false;
    this.loaded = true;
    this.assetPreloader.complete(this);
   
  }

  loadError() {
    console.log("!!loadError in BabylonSceneAsset!!");
    console.log(arguments);
  }
}
