import { useSceneEntities } from "@/stores/SceneEntities";

export function useAssetFaucett17494iCradleOnly(payload, viewport) {
    const sceneEntities = useSceneEntities();

    let t17464i_cradleonly = sceneEntities.getUIData("t17464i_cradleonly");
    let t17464i = sceneEntities.getUIData("t17464i");

    let t17494i_head_cradle = sceneEntities.configuratorView.viewportComponent.getViewportAsset("t17494i_head_cradle");
    t17494i_head_cradle.disabled = payload.disabled;
    t17494i_head_cradle.enabled = payload.enabled;
    viewport.setEnabled(t17494i_head_cradle);

    let head_cradle_hose_right = sceneEntities.configuratorView.viewportComponent.getViewportAsset("head_cradle_hose_right");
    head_cradle_hose_right.disabled = payload.disabled;
    head_cradle_hose_right.enabled = payload.enabled || (t17464i_cradleonly.enabled && t17464i.enabled);
    viewport.setEnabled(head_cradle_hose_right);

  

}