import { useButtonInteraction } from "@/scripts/ui/ButtonInteraction";
import { useComponentDisplayData } from "@/scripts/ui/ComponentDisplayData";
import { useComponentData } from "@/scripts/ui/ComponentData";
import { useComponentStylingType8 } from "@/scripts/ui/ComponentStylingType8";
export function useButtonsContainer2() {
  const data = {};
  data.component = "ConfigurableButtonsContainerComponent";
  let attr = (data.attributes = {});
  attr.componentData = useComponentData();
  attr.componentData.content = "CofigurableButtonContent5Component";
  attr.componentData.skipSerialization = true;
  attr.componentData.styling = useComponentStylingType8();
  attr.componentData.interaction = useButtonInteraction();
  attr.componentData.display = useComponentDisplayData();
  

  return data;
}
