import { createRouter, createWebHistory,createWebHashHistory } from 'vue-router'
import ApplicationLandingView from '@/views/ApplicationLandingView.vue'
import ApplicationView from '@/views/ApplicationView.vue'
import PageNotFoundView from '@/views/PageNotFoundView.vue'
import ConfiguratorWrapperView from '@/views/ConfiguratorWrapperView.vue'
import CacheUtilityView  from '@/views/CacheUtilityView.vue'


const routes = [

  {
    path: "/cacheutility",
    name: "cacheutility",
    component: CacheUtilityView,
    
  },

 
 
  {
    path: "/applicationlanding/:windowkey",
    name: "applicationlanding",
    component: ApplicationLandingView,
   
  },
  {
    path: "/",
    redirect: "/applicationlanding/roomselect",
  },
  {
    path: "",
    redirect: "/applicationlanding/roomselect",
  },
  {
    path: "/application",
    redirect: "/application/configurator",
    name: "application",
    component: ApplicationView,
   
    children: [
     
      {
        path: "configurator",
        component: ConfiguratorWrapperView,
      },
     
    ],
  },
  
  {
    path: '/404',
    name: 'PageNotFound',
    component: PageNotFoundView,
  },
  {
    path: "/:catchAll(.*)", // Unrecognized path automatically matches 404
   redirect: "/applicationlanding/roomselect",
  },
]


//set origin and pathname
window.location_origin = location.origin; 
window.location_pathname = location.pathname; 
console.log("default origin and path")
console.log("window.location_origin: "+window.location_origin)
console.log("window.location_pathname: "+window.location_pathname)
console.log("----------------")
let lastChar = window.location_pathname.substring(window.location_pathname.length-1);
console.log("window.location_pathname lastChar: "+lastChar)
if (lastChar === '/') {        
 window.location_pathname =  window.location_pathname.substring(0, window.location_pathname.length-1);
}
console.log("window.location_pathname (should have no trailing slash now): "+ window.location_pathname)
window.location_pathname = (window.location_pathname.indexOf('.html') !== -1)?window.location_pathname.substring(0,window.location_pathname.length-10):window.location_pathname
console.log("window.location_pathname (should have no index.html): "+ window.location_pathname)
window.location_url = window.location_origin+window.location_pathname;
console.log("window.location_url: "+window.location_url)
lastChar = window.location_url.substring(window.location_url.length-1);
console.log("window.location_url lastChar: "+lastChar)
if (lastChar !== '/') {        
window.location_url+="/";
}
console.log("window.location_url (should have a trailing slash now): "+ window.location_url)
const router = createRouter({
  history:createWebHashHistory(),// createWebHistory(window.location_pathname),
  routes
})

export default router
