import { useAccordion1 } from "@/scripts/ui/accordions/Accordion1";
import { uset17453 } from "@/scripts/ui/configurator_scene_navigation/general/faucets/t17453";
import { uset17464 } from "@/scripts/ui/configurator_scene_navigation/general/faucets/t17464";
import { uset17464i } from "@/scripts/ui/configurator_scene_navigation/general/faucets/t17464i";
import { uset17494i } from "@/scripts/ui/configurator_scene_navigation/general/faucets/t17494i";
import { uset17438 } from "@/scripts/ui/configurator_scene_navigation/general/faucets/t17438";
import { uset27959 } from "@/scripts/ui/configurator_scene_navigation/general/faucets/t27959";

import { useDivider1 } from "@/scripts/ui/dividers/Divider1";
import { useButton4 } from "@/scripts/ui/buttons/Button4";
import { useFlexContainer1 } from "@/scripts/ui/flex_containers/FlexContainer1";

import { useButtonRadioGroup } from "@/scripts/ui/ButtonRadioGroup";
import { useSceneEntities } from "@/stores/SceneEntities";
export function useFaucets() {
  const sceneEntities = useSceneEntities();
  let faucetsRadioGroup = useButtonRadioGroup();
  

  let target = useAccordion1();
  target.attributes.componentData.display.label = "Faucets";
  target.attributes.componentData.key = "faucets";
  sceneEntities.addItemToUIList(target.attributes.componentData);
  target.attributes.componentData.interaction.actions["scene_update"] = true;


 
  let targetChildren = target.attributes.componentData.children;

  let child = uset17453();
  targetChildren.push(child);
  child.attributes.componentData.interaction.radioGroup =
  faucetsRadioGroup;

  child = uset17464();
  targetChildren.push(child);
  child.attributes.componentData.interaction.radioGroup =
  faucetsRadioGroup;

  child = uset17464i();
  targetChildren.push(child);
  child.attributes.componentData.interaction.radioGroup =
  faucetsRadioGroup;

  child = uset17494i();
  targetChildren.push(child);
  child.attributes.componentData.interaction.radioGroup =
  faucetsRadioGroup;

  child = uset17438();
  targetChildren.push(child);
  child.attributes.componentData.interaction.radioGroup =
  faucetsRadioGroup;

  child = uset27959();
  targetChildren.push(child);
  child.attributes.componentData.interaction.radioGroup =
  faucetsRadioGroup;


  //Finish Options--------------------------------------------------
  let divider = useDivider1();
  targetChildren.push(divider);
  divider.attributes.componentData.display.useLeft = true;
  divider.attributes.componentData.display.useRight = true;
  divider.attributes.componentData.display.label = "Finish Options";
  divider.attributes.componentData.key = "divider_faucets_finish";
  sceneEntities.addItemToUIList(divider.attributes.componentData);

  //Flex Container--------------------------------------------------
  let flexContainer1 = useFlexContainer1();
  targetChildren.push(flexContainer1);
  let flexContainer1Children = flexContainer1.attributes.componentData.children;

  let option = useButton4();
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "Chrome";
  option.attributes.componentData.display.color = "#b5bbbd";
  
  option.attributes.componentData.key = "faucet_chrome";
  option.attributes.componentData.colorKey = "chrome";
  option.attributes.componentData.sharedKey = "allmetals";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;

  option = useButton4();
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "Brushed Nickle";
  option.attributes.componentData.display.color = "#aaa9a6";
 
  option.attributes.componentData.key = "faucet_brushed_nickel";
  option.attributes.componentData.colorKey = "brushed_nickel";
  option.attributes.componentData.sharedKey = "allmetals";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;

  option = useButton4();
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "Oil Bronze";
  option.attributes.componentData.display.color = "#554943";
 
  option.attributes.componentData.key = "faucet_oil_bronze";
  option.attributes.componentData.colorKey = "oil_bronze";
  option.attributes.componentData.sharedKey = "allmetals";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;

  option = useButton4();
  flexContainer1Children.push(option);
  option.attributes.componentData.display.label = "Matt Black";
  option.attributes.componentData.display.color = "#282828";
 
  option.attributes.componentData.key = "faucet_black";
  option.attributes.componentData.colorKey = "black";
  option.attributes.componentData.sharedKey = "allmetals";
  sceneEntities.addItemToUIList(option.attributes.componentData);
  option.attributes.componentData.interaction.deselectSelf = false;
  option.attributes.componentData.interaction.actions["scene_update"] = true;

  return target;
}
