import { useSceneEntities } from "@/stores/SceneEntities";
export function useAssetCornerShelvesValidate(payload, viewport) {
  const sceneEntities = useSceneEntities();
  //first check all the conditions that can cause disabling

  //walkin tubs
  let tub2852 = sceneEntities.getUIData("walkin2852");
  let tub2653 = sceneEntities.getUIData("walkin2653");
  let tub3053 = sceneEntities.getUIData("walkin3053");
  
  let mustDisable = false;
  if (tub2852.enabled || tub2653.enabled || tub3053.enabled ) {
    mustDisable = true;
  }

  let shelfLeftTop = sceneEntities.getUIData("accessories_shelflefttop");
  let shelfLeftMid = sceneEntities.getUIData("accessories_shelfleftmid");
  let shelfLeftBottom = sceneEntities.getUIData("accessories_shelfleftbottom");

  let shelfRightTop = sceneEntities.getUIData("accessories_shelfrighttop");
  let shelfRightMid = sceneEntities.getUIData("accessories_shelfrightmid");
  let shelfRightBottom = sceneEntities.getUIData("accessories_shelfrightbottom");

  let dividerShelves = sceneEntities.getUIData("divider_shelves");
  let dividerShelvesFinish = sceneEntities.getUIData("corner_shelf_finish");

  let finishSentrel = sceneEntities.getUIData("corner_shelf_sentrel_material");
  let finishMetal = sceneEntities.getUIData("corner_shelf_metal_material");

  shelfLeftTop.disabled = mustDisable;
  shelfLeftMid.disabled = mustDisable;
  shelfLeftBottom.disabled = mustDisable;

  shelfRightTop.disabled = mustDisable;
  shelfRightMid.disabled = mustDisable;
  shelfRightBottom.disabled = mustDisable;

  dividerShelves.disabled = mustDisable;
  dividerShelvesFinish.disabled = mustDisable;

  finishSentrel.disabled = mustDisable;
  finishMetal.disabled = mustDisable;

  // new logic if disabled then set enabled to false
  //button wrapped in conditionals but not divider .. since they must be reenabled when not disabled as they are not interactive. 

  if (shelfLeftTop.enabled && shelfLeftTop.disabled) {
    shelfLeftTop.enabled = false;
  }

  if (shelfLeftMid.enabled && shelfLeftMid.disabled) {
    shelfLeftMid.enabled = false;
  }

  if (shelfLeftBottom.enabled && shelfLeftBottom.disabled) {
    shelfLeftBottom.enabled = false;
  }

  if (shelfRightTop.enabled && shelfRightTop.disabled) {
    shelfRightTop.enabled = false;
  }

  if (shelfRightMid.enabled && shelfRightMid.disabled) {
    shelfRightMid.enabled = false;
  }

  if (shelfRightBottom.enabled && shelfRightBottom.disabled) {
    shelfRightBottom.enabled = false;
  }

  dividerShelves.enabled = !dividerShelves.disabled;
  dividerShelvesFinish.enabled = !dividerShelvesFinish.disabled;

  if (finishSentrel.enabled && finishSentrel.disabled) {
    finishSentrel.enabled = false;
  }

  if (finishMetal.enabled && finishMetal.disabled) {
    finishMetal.enabled = false;
  }

  if(!mustDisable){
    if(!finishSentrel.enabled && !finishMetal.enabled){
      finishSentrel.enabled = true;
    }
  }

  //sentrel shelves

  //left
  let shelfLeftTopAsset = sceneEntities.configuratorView.viewportComponent.getViewportAsset("accessories_shelflefttop");
  shelfLeftTopAsset.disabled = mustDisable;
  shelfLeftTopAsset.enabled = shelfLeftTop.enabled && finishSentrel.enabled;
  viewport.setEnabled(shelfLeftTopAsset);

  let shelfLeftMidAsset = sceneEntities.configuratorView.viewportComponent.getViewportAsset("accessories_shelfleftmid");
  shelfLeftMidAsset.disabled = mustDisable;
  shelfLeftMidAsset.enabled = shelfLeftMid.enabled && finishSentrel.enabled;
  viewport.setEnabled(shelfLeftMidAsset);

  let shelfLeftBottomAsset =
    sceneEntities.configuratorView.viewportComponent.getViewportAsset("accessories_shelfleftbottom");
  shelfLeftBottomAsset.disabled = mustDisable;
  shelfLeftBottomAsset.enabled = shelfLeftBottom.enabled && finishSentrel.enabled;
  viewport.setEnabled(shelfLeftBottomAsset);

  //right
  let shelfRightTopAsset =
    sceneEntities.configuratorView.viewportComponent.getViewportAsset("accessories_shelfrighttop");
  shelfRightTopAsset.disabled = mustDisable;
  shelfRightTopAsset.enabled = shelfRightTop.enabled && finishSentrel.enabled;
  viewport.setEnabled(shelfRightTopAsset);

  let shelfRightMidAsset =
    sceneEntities.configuratorView.viewportComponent.getViewportAsset("accessories_shelfrightmid");
  shelfRightMidAsset.disabled = mustDisable;
  shelfRightMidAsset.enabled = shelfRightMid.enabled && finishSentrel.enabled;
  viewport.setEnabled(shelfRightMidAsset);

  let shelfRightBottomAsset =
    sceneEntities.configuratorView.viewportComponent.getViewportAsset("accessories_shelfrightbottom");
  shelfRightBottomAsset.disabled = mustDisable;
  shelfRightBottomAsset.enabled = shelfRightBottom.enabled && finishSentrel.enabled;
  viewport.setEnabled(shelfRightBottomAsset);

  //metal shelves

  //left
  shelfLeftTopAsset = sceneEntities.configuratorView.viewportComponent.getViewportAsset(
    "accessories_shelflefttop_metal"
  );
  shelfLeftTopAsset.disabled = mustDisable;
  shelfLeftTopAsset.enabled = shelfLeftTop.enabled && finishMetal.enabled;
  viewport.setEnabled(shelfLeftTopAsset);

  shelfLeftMidAsset = sceneEntities.configuratorView.viewportComponent.getViewportAsset(
    "accessories_shelfleftmid_metal"
  );
  shelfLeftMidAsset.disabled = mustDisable;
  shelfLeftMidAsset.enabled = shelfLeftMid.enabled && finishMetal.enabled;
  viewport.setEnabled(shelfLeftMidAsset);

  shelfLeftBottomAsset = sceneEntities.configuratorView.viewportComponent.getViewportAsset(
    "accessories_shelfleftbottom_metal"
  );
  shelfLeftBottomAsset.disabled = mustDisable;
  shelfLeftBottomAsset.enabled = shelfLeftBottom.enabled && finishMetal.enabled;
  viewport.setEnabled(shelfLeftBottomAsset);

  //right
  shelfRightTopAsset = sceneEntities.configuratorView.viewportComponent.getViewportAsset(
    "accessories_shelfrighttop_metal"
  );
  shelfRightTopAsset.disabled = mustDisable;
  shelfRightTopAsset.enabled = shelfRightTop.enabled && finishMetal.enabled;
  viewport.setEnabled(shelfRightTopAsset);

  shelfRightMidAsset = sceneEntities.configuratorView.viewportComponent.getViewportAsset(
    "accessories_shelfrightmid_metal"
  );
  shelfRightMidAsset.disabled = mustDisable;
  shelfRightMidAsset.enabled = shelfRightMid.enabled && finishMetal.enabled;
  viewport.setEnabled(shelfRightMidAsset);

  shelfRightBottomAsset = sceneEntities.configuratorView.viewportComponent.getViewportAsset(
    "accessories_shelfrightbottom_metal"
  );
  shelfRightBottomAsset.disabled = mustDisable;
  shelfRightBottomAsset.enabled = shelfRightBottom.enabled && finishMetal.enabled;
  viewport.setEnabled(shelfRightBottomAsset);
}
